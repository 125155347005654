import React, { Component } from 'react'
import { Calendar, Icon, Button, DatePicker, Input, message, Select, Switch } from 'antd';
import Modal from 'react-modal';
import { observer } from 'mobx-react';
import { FaBirthdayCake } from 'react-icons/fa';
import _ from "underscore";
import moment from 'moment';
import { createEvent, deleteEvent, editEvent } from '../api_calls/event';
import {icons as categoryIcons} from '../utils/events/categoryIcons';

const { Option } = Select;

Modal.setAppElement('#root')
@observer(['FamilyStore', 'UserStore', 'AuthStore'])
class Events extends Component {

    constructor(props) {
        super(props);
        this.getListData = this.getListData.bind(this);
        this.dateCellRender = this.dateCellRender.bind(this);
        this.getMonthData = this.getMonthData.bind(this);
        this.monthCellRender = this.monthCellRender.bind(this);
        this.formatBday = this.formatBday.bind(this);
        this.state = {
            group: null,
            canAdd: true,
            addEventModalOpen: false,
            recurring: false,
            recurrValue: 'yearly',
            onePerson: false,
            selectedEvent: {}
        };
        this.onSubmitEvent = this.onSubmitEvent.bind(this);
        this.onSubmitEdits = this.onSubmitEdits.bind(this);
        this.deleteSelectedEvent = this.deleteSelectedEvent.bind(this);
    }

    getListData(value) {
        let listData;
        let group = this.state.group;
        let membersWithEvents = _.where(group, { birthDay: value.date(), birthMonth: value.month() + 1 });
        if (membersWithEvents) {
            listData = membersWithEvents.map((member) => {
                return <div><FaBirthdayCake /> {member.name}'s Birthday</div>
            });
        }
        const groupEvents = this.props.FamilyStore.events;
        if (groupEvents.length) {
            let eventList = _.filter(groupEvents, (e) => new Date(e.date).getDate() == value.date() &&
                new Date(e.date).getMonth() == value.month() &&
                (e.isOneTime ? new Date(e.date).getUTCFullYear() == value.year() : true)).map((ge) => {
                    return this.eventComponent(ge);
                })
            listData = listData.concat(eventList);
        }
        return listData || [];
    }

    eventComponent(groupEvent) {
        return <div
            onClick={() => {
                if (this.props.UserStore.can("edit-events")) {
                    this.setState({ selectedEvent: groupEvent, editEventModalOpen: true })
                }
            }}
        >
          {groupEvent.category ? _.findWhere(categoryIcons({fontSize: "18pt"}), {id: groupEvent.category}).component  : ""}  {groupEvent.title}
        </div>
    }

    dateCellRender(value) {
        let listData = this.getListData(value);
        return (
            <ul className="calendar-events">
                {listData}
            </ul>
        );
    }

    formatBday(bday) {
        let bdayString = String(bday);
        let thNums = [0, 4, 5, 6, 7, 8, 9];
        if (bday < 11 || bday > 13) {
            if (thNums.includes(Number(bdayString.slice(-1)))) {
                return "th";
            }
            //NOTE: Not triple equal intentionally
            // eslint-disable-next-line
            if (bdayString.slice(-1) == 1) {
                return "st";
            }
            // eslint-disable-next-line
            if (bdayString.slice(-1) == 2) {
                return "nd";
            }
            // eslint-disable-next-line
            if (bdayString.slice(-1) == 3) {
                return "rd";
            }
        }
        if (bday > 10 && bday < 14) {
            return "th";
        }
        return "";
    }

    getMonthData(value) {
        let listData = [];
        let group = this.state.group;
        let membersWithEvents = _.where(group, { birthMonth: value.month() + 1 });
        if (membersWithEvents) {
            listData = membersWithEvents.map((member) => {
                return <div><FaBirthdayCake /> {member.name}'s birthday on the {member.birthDay + this.formatBday(member.birthDay)}</div>
            });
        }
        return listData || [];

    }

    monthCellRender(value) {
        const num = this.getMonthData(value);
        return num ? (
            <div className="calendar-notes-month">
                {num}
            </div>
        ) : null;
    }


    onSubmitEdits() {
        const token = this.props.AuthStore.userAuth.auth.accessToken;
        let $set = {};
        if ((this.state.edit_recurring) && !this.state.edit_recurrValue) {
            message.warn("Choose how often to recurr");
        }
        else if ((this.state.edit_onePerson !== undefined) && this.state.edit_onePerson && !this.state.edit_personChosen) {
            message.warn("Must choose who event is for, or make event 'For all'");
        }
        else {

            if (this.state.edit_recurring !== undefined) {
                $set.isOneTime = !this.state.edit_recurring
            }
            if (this.state.edit_recurring) {
                $set.recurr = this.state.edit_recurrValue;
            }
            if (this.state.edit_category) {
                $set.category = this.state.edit_category;
            }
            if (this.state.edit_description) {
                $set.description = this.state.edit_description;
            }
            if (this.state.edit_personChosen) {
                $set.userId = this.state.edit_personChosen;
            }
            if (this.state.edit_title) {
                $set.title = this.state.edit_title;
            }
            if (this.state.edit_date) {
                $set.date = this.state.edit_date;
            }
            this.setState({ editEventModalOpen: false });
            const update = { $set, $unset: {} };
            if ($set.isOneTime) {
                update.$unset.recurr = 1;
            }
            if (!!this.state.selectedEvent.userId !== this.state.edit_onePerson && this.state.selectedEvent.userId && (this.state.edit_onePerson !== undefined)) {
                console.log("Unsetting");
                update.$unset.userId = 1;
            }
            editEvent(this.state.selectedEvent._id, token, update).then((res, err) => {
                if (res && res.status && res.status === 200) {
                    message.success("Event updated!");
                    this.props.FamilyStore.loadEvents();
                }
                else {
                    message.error(err || "Error updating event");
                }
                this.setState({
                    edit_onePerson: undefined,
                    edit_category: undefined,
                    edit_date: undefined,
                    edit_description: undefined,
                    edit_personChosen: undefined,
                    edit_recurring: undefined,
                    edit_recurrValue: undefined,
                    edit_title: undefined
                });
            });
        }
    }



    onSubmitEvent() {
        if (!this.state.title) {
            message.warn("Need a title");
        }
        else if (!this.state.date) {
            message.warn("Need a date");
        }
        else if (this.state.onePerson && !this.state.personChosen) {
            message.warn("Must choose who event is for, or make event 'For all'");
        }
        else {
            const token = this.props.AuthStore.userAuth.auth.accessToken;
            const evt = {
                creatorId: this.props.UserStore.user._id,
                groupId: this.props.FamilyStore.activeGroupId,
                title: this.state.title,
                date: this.state.date,
                isOneTime: !this.state.recurring
            }
            if (this.state.recurring) {
                evt.recurr = this.state.recurrValue;
            }
            if (this.state.category) {
                evt.category = this.state.category;
            }
            if (this.state.description) {
                evt.description = this.state.description;
            }
            if (this.state.onePerson && this.state.personChosen) {
                evt.userId = this.state.personChosen;
            }
            console.log(evt);
            this.setState({ addEventModalOpen: false });
            createEvent(token, evt).then((res) => {
                if (res && res.status && res.status === 200) {
                    this.setState({
                        category: undefined,
                        date: undefined,
                        description: undefined,
                        personChosen: undefined,
                        recurring: false,
                        recurrValue: 'yearly',
                        onePerson: false,
                        title: undefined
                    });
                    this.props.FamilyStore.loadEvents();
                }
            });
        }
    }

    deleteSelectedEvent() {
        const token = this.props.AuthStore.userAuth.auth.accessToken;
        this.setState({ editEventModalOpen: false });
        deleteEvent(this.state.selectedEvent._id, token).then((res, err) => {
            if (res && res.status && res.status === 200) {
                message.success("Event deleted!");
                this.props.FamilyStore.loadEvents();
            }
            else {
                message.error(err || "Error deleting event");
            }
        });
    }

    render() {
        const { FamilyStore, UserStore } = this.props;
        let shouldRender = FamilyStore.eventsLoaded;
        let group = [];

        FamilyStore.family.forEach((member) => {
            group.push(member);
        });

        if (!this.state.group && group.length > 0) {
            this.setState({ group });
        }

        return (
            <div>
                <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                    isOpen={this.state.editEventModalOpen}
                    onRequestClose={() => { (this.state.submittingEventEdits ? console.log("tried to close") : this.setState({ editEventModalOpen: false })) }}>
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ color: "white" }} className="AppFont">Edit Event</h2>
                        <div>
                            <Switch
                                checkedChildren="Recurring"
                                unCheckedChildren="One-time"
                                defaultChecked={!this.state.selectedEvent.isOneTime}
                                onChange={(val) => this.setState({ edit_recurring: val })} />
                        </div>
                        <div style={{ padding: '7px' }}>
                            <Switch
                                checkedChildren="For one person"
                                unCheckedChildren="For all"
                                defaultChecked={!!this.state.selectedEvent.userId}
                                onChange={(val) => this.setState({ edit_onePerson: val })} />
                        </div>
                        <div style={{ display: "inline-grid" }}>
                            {this.state.edit_onePerson || (this.state.edit_onePerson == undefined && this.state.selectedEvent.userId) ? <div>
                                For Who
                                <Select
                                    id="personForEventSelect"
                                    style={{ width: "100%" }}
                                    placeholder=""
                                    defaultValue={this.state.selectedEvent.userId}
                                    onChange={(val) => { this.setState({ edit_personChosen: val }) }}
                                >
                                    {this.props.FamilyStore.family.map((familyMember) => {
                                        return <Option value={familyMember._id}>{familyMember.name}</Option>
                                    })}
                                </Select>
                            </div>
                                : ""}
                            <br />
                            Title <Input defaultValue={this.state.selectedEvent.title} onChange={(e) => { this.setState({ edit_title: e.target.value }) }} placeholder="Title" />
                            <br />
                            Description <Input defaultValue={this.state.selectedEvent.description} onChange={(e) => { this.setState({ edit_description: e.target.value }) }} placeholder="Description" />
                            <br />
                            Date <DatePicker defaultValue={moment(this.state.selectedEvent.date)} onChange={(value) => { this.setState({ edit_date: value ? value.unix() * 1000 : null }) }} />
                            <br />
                            Category <Select
                            defaultValue={this.state.selectedEvent.category}
                                onChange={(val) => { this.setState({ edit_category: val }) }}
                                >{categoryIcons({fontSize: "18pt"}).map(icon => <Option value={icon.id}>{icon.component} {icon.name}</Option>)}</Select>
                            {this.state.edit_recurring ? <Select
                                id="recurringEventSelect"
                                style={{ width: '100%' }}
                                placeholder=""
                                defaultValue={this.state.selectedEvent.recurr}
                                onChange={(val) => { this.setState({ edit_recurrValue: val }) }}

                            >
                                <Option value='yearly'>Anually</Option>
                            </Select>
                                : ""}
                            <br />
                            <Button className="AppFont btn-submit" onClick={this.onSubmitEdits} size="large"><Icon type="check-circle" /> Submit Edits</Button>
                            <Button className="AppFont btn-cancel" onClick={() => { this.setState({ editEventModalOpen: false }) }} size="large"><Icon type="close" /> Cancel</Button>
                            {this.props.UserStore.can("delete-events") ?
                                <Button style={{ backgroundColor: "red" }} onClick={this.deleteSelectedEvent} size="large"><Icon type="close" /> Delete</Button>
                                : ""}
                        </div>
                    </div>
                </Modal>
                <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                    isOpen={this.state.addEventModalOpen}
                    onRequestClose={() => { (this.state.submittingEvent ? console.log("tried to close") : this.setState({ addEventModalOpen: false })) }}>
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ color: "white" }} className="AppFont">New Event</h2>
                        <div>
                            <Switch
                                checkedChildren="Recurring"
                                unCheckedChildren="One-time"
                                onChange={() => { this.setState({ recurring: !this.state.recurring }) }} />
                        </div>
                        <div style={{ padding: '7px' }}>
                            <Switch
                                checkedChildren="For one person"
                                unCheckedChildren="For all"
                                onChange={() => { this.setState({ onePerson: !this.state.onePerson }) }} />
                        </div>
                        <div style={{ display: "inline-grid" }}>
                            {this.state.onePerson ? <div>
                                For Who
                                <Select
                                    id="personForEventSelect"
                                    style={{ width: "100%" }}
                                    placeholder=""
                                    onChange={(val) => { this.setState({ personChosen: val }) }}
                                >
                                    {this.props.FamilyStore.family.map((familyMember) => {
                                        return <Option value={familyMember._id}><img width="25px" src={familyMember.profileImage}/>{familyMember.name}</Option>
                                    })}
                                </Select>
                            </div>
                                : ""}
                            <br />
                            Title <Input onChange={(e) => { this.setState({ title: e.target.value }) }} placeholder="Title" />
                            <br />
                            Description <Input onChange={(e) => { this.setState({ description: e.target.value }) }} placeholder="Description" />
                            <br />
                            Date <DatePicker onChange={(value) => { this.setState({ date: value ? value.unix() * 1000 : null }) }} />
                            <br />
                                Category <Select
                                onChange={(val) => { this.setState({ category: val }) }}
                                >{categoryIcons({fontSize: "18pt"}).map(icon => <Option value={icon.id}>{icon.component} {icon.name}</Option>)}</Select>
                            {this.state.recurring ? <Select
                                id="recurringEventSelect"
                                style={{ width: '100%' }}
                                placeholder=""
                                defaultValue="yearly"
                                onChange={(val) => { this.setState({ recurrValue: val }) }}

                            >
                                <Option value='yearly'>Anually</Option>
                            </Select>
                                : ""}
                            <br />
                            <Button className="AppFont btn-submit" onClick={this.onSubmitEvent} size="large"><Icon type="check-circle" /> Submit Event</Button>
                            <Button className="AppFont btn-cancel" onClick={() => { this.setState({ addEventModalOpen: false }) }} size="large"><Icon type="close" /> Cancel</Button>
                        </div>
                    </div>
                </Modal>
                {this.props.UserStore.can('create-events', this.props.FamilyStore.activeGroupId) ? <Button style={{  backgroundColor: UserStore.theme().icons, color: UserStore.theme().font2, borderRadius: "40px" }} onClick={() => { this.setState({ addEventModalOpen: true }) }} size="large"><Icon type="plus" /> Add Event</Button>
                    : ""}
                {shouldRender ? <Calendar dateCellRender={this.dateCellRender} monthCellRender={this.monthCellRender} /> : <Icon type="loading" />}
            </div>
        )
    }
}
export default Events;