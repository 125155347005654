import React, { Component } from 'react'
import { observer } from 'mobx-react';
import  humanizeDuration  from 'humanize-duration';

@observer(['FamilyStore'])
class UpcomingBirthday extends Component {
    render() {      

        return (
            <div>
                {this.props.FamilyStore.loaded ?
                this.props.FamilyStore.family.length > 0 ?
                    <div style={{ backgroundColor: "rgba(0,0,0,0.65)" }}>
                        <span className="AppFont BdayTitle" style={{ color: "white"}}>{this.props.FamilyStore.nextBirthdayName}'s birthday is in {humanizeDuration(this.props.FamilyStore.nextBirthday - Date.now(), { largest: 2, round: true })} 
                     </span>
                       
                        <div className="AppFont BdayTitleDate">{this.props.FamilyStore.nextBirthday.toDateString()}</div>

                        {this.props.FamilyStore.bdaysUnder30.length > 0 ?
                            <div className="BdaySubtitle"><span className="AppFont" style={{ fontWeight: "bold", fontSize: "16pt", color: "white" }}>Also Coming Soon:</span>
                                <br />
                                {this.props.FamilyStore.bdaysUnder30.map(birthday => <div className="AppFont" style={{ fontWeight: "bold", fontSize: "14pt", color: "#1890ff" }}>
                                    {birthday.name}'s on {new Date(birthday.date).toLocaleDateString()}</div>)}
                            </div> : ""}

                    </div>
                    : <div className="AppFont" style={{ fontWeight: "bold", fontSize: "34pt", color: "white" }}>No group members found</div>
                    : "loading upcoming birthdays"}

            </div>
        )
    }
}

export default UpcomingBirthday;
