import React, { Component } from 'react'
import { observer } from 'mobx-react';
import { Button, InputNumber, Icon, message } from "antd";
import { updateUserBirthday } from "../api_calls/user";
@observer(["AuthStore", "UserStore"])
class WelcomeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            submitButton: <Button onClick={this.onSubmitClicked.bind(this)} size="large">Submit birth info and agree to terms</Button>,
            birthMonth: 0,
            birthDay: 0,
            disabled: false
        }
        this.onDayChange = this.onDayChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.onSubmitClicked = this.onSubmitClicked.bind(this);
    }
    onDayChange(value) {
        this.setState({ birthDay: value });
    }
    onMonthChange(value) {
        this.setState({ birthMonth: value });
    }
    onSubmitClicked() {
        if (this.state.birthDay === 0) {
            message.warn("Please select your birth day.");
        } else if (this.state.birthMonth === 0) {
            message.warn("Please select your birth month.");
        } else {
            let self = this;
            this.setState({ submitButton: <Icon style={{ color: "#61dafb", fontSize: "32pt" }} type="loading" />, disabled: true });
            updateUserBirthday(this.props.UserStore.user._id, this.props.AuthStore.userAuth.auth.accessToken, this.state.birthDay, this.state.birthMonth).then(() => {
                self.props.UserStore.refreshUser(this.props.AuthStore.userAuth.auth.accessToken);
            })
        }
    }
    render() {
        return (
            <div className="AppFont" style={{ backgroundColor: "rgba(0,0,0,0.6", padding: "115px", borderRadius: "120px" }}>
                <h1 style={{ color: "white", fontSize: "46pt" }}>Welcome to list.gifts!</h1>
                <p style={{ color: "white", fontSize: "24pt" }}>To get the most out of list.gifts please upload a custom profile image in the settings drawer and input your birth day and month below.</p>
                <span style={{ color: "white", fontSize: "20pt" }}>Birth Day </span>
                <InputNumber disabled={this.state.disabled} onChange={this.onDayChange} min={1} max={31} size="large" />
                <br />
                <br />
                <span style={{ color: "white", fontSize: "20pt" }}>Birth Month </span>
                <InputNumber disabled={this.state.disabled} onChange={this.onMonthChange} min={1} max={12} size="large" />
                <br />
                <p style={{ color: "white", fontSize: "12pt" }}>By using this site you agree to not hold the creator responsible in the case of any lost or stolen data. Do not put sensitive data on this website.</p>
                <br />
                {this.state.submitButton}
            </div>
        )
    }
}
export default WelcomeModal;