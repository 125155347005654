import { observable, action, computed } from 'mobx';

class ViewStore {


    @observable view = { activeView: 'events', gridList: 'grid', showHeader: null, login: false, dimLogin: false, currentPage: '', previousPage: '' };


    // Change View
    @action changeView = (view) => {
        this.view.activeView = view;
    }

    @computed get activeView() {
        return this.view.activeView;
    }

    @action changeCurrentPage = (pageName) => {
        this.view.currentPage = pageName;
    }

    @computed get getCurrentPage() {
        return this.view.currentPage;
    }

    @action changePreviousPage = (pageName) => {
        this.view.previousPage = pageName;
    }

    @computed get getPreviousPage() {
        return this.view.previousPage;
    }


    @action setDimLogin = () => {
        this.view.dimLogin = !this.view.dimLogin;
    }

    @computed get getDimLogin() {
        return this.view.dimLogin;
    }

    // Login View
    @action changeLoginView = (view) => {
        this.view.login = !this.view.login;
    }

    @computed get loginView() {
        return this.view.login;
    }

    // Grid or List Options
    @action changeGridList = (view) => {
        if (this.view.gridList === 'list') {
            this.view.gridList = 'grid';
        } else {
            this.view.gridList = 'list';
        }
    }

    @computed get getGridList() {
        return this.view.gridList;
    }

    // Show/Hide Header
    @action changeHeaderState = (view) => {
        this.view.showHeader = !this.view.showHeader;
    }

    @computed get getHeaderState() {
        return this.view.showHeader;
    }

}

const store = new ViewStore();
export default store;