import React, { Component } from 'react';
import { Button, Input, Icon, Dropdown, Menu, message } from 'antd';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import { addPersonalNote } from '../api_calls/user';
const { TextArea } = Input;

@observer(['UserStore', 'AuthStore', 'FamilyStore'])
class AddPersonalNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            note: "",
            modalOpen: false,
            submittingNewNote: false,
            stepOneComplete: false
        };
        this.onSubmitClick = this.onSubmitClick.bind(this);
        this.stepOne = this.stepOne.bind(this);
        this.stepTwo = this.stepTwo.bind(this);
        this.memberClicked = this.memberClicked.bind(this);
    }
    memberClicked(member) {
        this.setState({ stepOneComplete: true, personSelected: member, memberSelected: true });
    }
    stepOne() {
        const { UserStore, AuthStore, FamilyStore } = this.props;
        let dropdownContents = [];
        FamilyStore.family.forEach(member => {
            dropdownContents.push(<Menu.Item onClick={this.memberClicked.bind(this, member)} key={member._id}>{member.name}</Menu.Item>);
        });
        UserStore.user.nonUserPeople.forEach((person, idx) => {
            dropdownContents.push(<Menu.Item key={idx}>{person.name}</Menu.Item>);
        });
        
        const menu = (<Menu>{dropdownContents}</Menu>);
        return <div>
            <span style={{ fontSize: "16pt", color: "#3CAEA3" }} className="AppFont">Who is this about?</span>
            <br />
            <Dropdown overlay={menu}>
                <span style={{cursor: "pointer", color: "rgb(24, 144, 255)"}} className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                    Someone in my group <Icon type="down" />
                </span>
            </Dropdown>
            <br />
            <Button style={{ backgroundColor: "rgba(0,0,0,0", color: "#1890ff", border: "none" }} onClick={() => { this.setState({ stepOneComplete: true, noneSelected: true }) }}>Nobody</Button>
            <br />
            <Button style={{ backgroundColor: "rgba(0,0,0,0", color: "#1890ff", border: "none" }} onClick={() => { this.setState({ stepOneComplete: true, otherSelected: true }) }}>Someone Else</Button>
            <br />
            <br />
            <Button className="AppFont btn-cancel" style={{color: "white", backgroundColor: "#ED553B"}} onClick={() => { this.setState({ modalOpen: false, stepOneComplete: false }) }} size="large"><Icon type="close" /> Cancel</Button>
        </div>;
    }
    stepTwo() {
        return <div>
            <span>{this.state.personSelected ? "New note about " + this.state.personSelected.name.slice(0,1).toUpperCase().concat(this.state.personSelected.name.slice(1)) : `New${this.state.noneSelected ? " General": ""} Note`}</span>
            <br />
            {this.state.otherSelected ? 
            <div>About who? <Input onChange={e => this.setState({newPerson: e.target.value})}></Input></div>
             : ""}
             <br />
             Note Content:
            <TextArea onChange={(e) => {this.setState({note: e.target.value})}} />
            <Button style={{ backgroundColor: "rgba(0,0,0,0", color: "#1890ff", border: "none" }} onClick={() => { this.setState({ stepOneComplete: false, otherSelected: false, noneSelected: false, personSelected: "" }) }}><Icon type="left" />Go Back</Button>
            <br />
            <Button className="AppFont btn-submit" style={{color: "white", backgroundColor: "#F6D55C" }} onClick={this.onSubmitClick.bind(this)} size="large"><Icon type="check-circle" /> Submit</Button>
            <Button className="AppFont btn-cancel" style={{color: "white", backgroundColor: "#ED553B"}} onClick={() => { this.setState({ modalOpen: false, stepOneComplete: false }) }} size="large"><Icon type="close" /> Cancel</Button>
        </div>;
    }
    onSubmitClick() {
        const { UserStore, AuthStore } = this.props;
        let self = this;
        self.setState({ submittingNewNote: true });
        let user = UserStore.childViewActive ? UserStore.activeChild.id : UserStore.user._id;
        console.log(this.state.note);
        let noteObject = {body: this.state.note, aboutGroupMember: !!this.state.memberSelected, aboutPerson: this.state.personSelected ? this.state.personSelected._id : this.state.newPerson || ""};
        addPersonalNote(user, noteObject, AuthStore.userAuth.auth.accessToken).then(() => {
            message.success("Note added");
            self.setState({ submittingNewNote: false, modalOpen: false, stepOneComplete: false, otherSelected: false, personSelected: "", noneSelected: false });
            UserStore.refreshUser(AuthStore.userAuth.auth.accessToken);
        });
    }
    render() {
        const { UserStore } = this.props;
        return (
            <div>
                <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                    isOpen={this.state.modalOpen}
                    onRequestClose={() => { (this.state.submittingNewNote ? console.log("tried to close") : this.setState({ modalOpen: false })) }}>
                    <div style={{textAlign: "center"}}>
                        {!this.state.stepOneComplete ? this.stepOne() : null}
                        {this.state.stepOneComplete ? this.stepTwo() : null}
                        {/* <span style={{ fontSize: "16pt", color: "#3CAEA3" }} className="AppFont">New Note:</span>
                        <br />
                        <TextArea onChange={(e) => { this.setState({ note: e.target.value }) }} placeholder="I always like..." autosize />
                        <br />
                        <br />
                        <Button className="AppFont" style={{ color: "white", backgroundColor: "#F6D55C" }} onClick={this.onSubmitClick.bind(this)} size="large"><Icon type="check-circle" /> Submit</Button>
                        <Button className="AppFont" style={{ color: "white", backgroundColor: "#ED553B" }} onClick={() => { this.setState({ modalOpen: false }) }} size="large"><Icon type="close" /> Cancel</Button> */}
                    </div>
                </Modal>
                <Button  style={{ backgroundColor: UserStore.theme().icons, color: UserStore.theme().font2, borderRadius: "40px"}} onClick={() => this.setState({ modalOpen: true })} size="large"><Icon type="file-add" />Add Note</Button>
                
            </div>
        );
    }
}

export default AddPersonalNote;