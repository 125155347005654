import React, { Component } from 'react'
import AddGift from './AddGift';
import AddNote from './AddNote';
import GiftCards from './GiftCards';
import WelcomeModal from './WelcomeModal';
import Notes from './Notes';
import Modal from 'react-modal'
import { observer } from 'mobx-react';
import { Icon, Affix, Button, message, InputNumber } from 'antd';
import _ from "underscore";
import { determineCategoryIcon } from '../utils/gifts/helpers.js'
import FamilyStore from '../stores/FamilyStore';
import { updateUserGiftRanks } from '../api_calls/user';
import User from '../models/UserModel';
import { appColours as themes } from '../utils/styles/colours';

// import AWS from "aws-sdk";

// var albumBucketName = "listgifts-pics";
// var bucketRegion = 'us-east-2';
// var IdentityPoolId = "us-east-2:e467c664-233b-4e2b-ac1f-36d6fb672768";


// AWS.config.update({
//   region: bucketRegion,
//   credentials: new AWS.CognitoIdentityCredentials({
//     IdentityPoolId: IdentityPoolId
//   })
// });

// var s3 = new AWS.S3({
//   apiVersion: "2006-03-01",
//   params: { Bucket: albumBucketName }
// });
Modal.setAppElement('#root')
@observer(["MyListStore", "UserStore", "FamilyStore", "AuthStore"])
class MyList extends Component {
    constructor(props) {
        super(props);
        const { FamilyStore, UserStore } = this.props;
        this.state = { ranksDoneForGroupChange: true, multipleGroups: false, rankModalOpen: false, rankings: [], ranks: {}, startingRanks: {}, appColours: themes("default")  };
        const imageFile = this.props.UserStore.childViewActive ?
            (_.findWhere(UserStore.children, { _id: UserStore.activeChild.id }) ? _.findWhere(UserStore.children, { _id: UserStore.activeChild.id }).profileImage : UserStore.profileImage)
            : UserStore.profileImage;
        setTimeout(() => {
            var imgtag = document.getElementsByClassName("profile-img");
            for (let item of imgtag) {
                item.src = imageFile;
            }
        }, 250);
        // this.listAlbums = this.listAlbums.bind(this);
        // this.createAlbum = this.createAlbum.bind(this);
        // this.viewAlbum = this.viewAlbum.bind(this);
        // this.addPhoto = this.addPhoto.bind(this);
        // this.deletePhoto = this.deletePhoto.bind(this);
        // this.deleteAlbum = this.deleteAlbum.bind(this);   
        this.onSubmitRankClick = this.onSubmitRankClick.bind(this);
        this.rankChanged = this.rankChanged.bind(this);
        this.clearGiftRank = this.clearGiftRank.bind(this);
    }

    componentDidMount() {
        const { UserStore, MyListStore, FamilyStore } = this.props;
        if (FamilyStore.activeGroupId !== "") {
            const realUser = UserStore.childViewActive ? UserStore.activeChild.id : UserStore.user._id;
            MyListStore.loadGiftsForUser(realUser, realUser, FamilyStore.activeGroupId);
        }
        this.setState({
            currentGroup: FamilyStore.activeGroupId
        });
    }

    determineRanking() {
        const { UserStore, FamilyStore, MyListStore } = this.props;
        const user = UserStore.childViewActive ? _.findWhere(UserStore.children, { _id: UserStore.activeChild.id }) : UserStore.user;
        const ranks = user.listRank.get(FamilyStore.activeGroupId);
        let allGifts = MyListStore.gifts.map(g => g._id);
        if (ranks && ranks.length) {
            ranks.forEach((rank, i) => {
                let currentRanks = this.state.startingRanks;
                if (allGifts.includes(rank)) {
                    currentRanks[rank] = i + 1;
                }
                this.setState({ rankModalOpen: false, ranksDone: true, startingRanks: currentRanks, ranks: currentRanks });
            });
        }
        else {
            this.setState({ rankModalOpen: false, ranksDone: true, startingRanks: {}, ranks: {} });
        }
    }

    determineRankingChild() {
        const { UserStore, FamilyStore, MyListStore } = this.props;
        const user = UserStore.childViewActive ? _.findWhere(UserStore.children, { _id: UserStore.activeChild.id }) : UserStore.user;
        const ranks = user.listRank.get(FamilyStore.activeGroupId);
        let giftsToMap = [];
        if (UserStore.childViewActive) {
            giftsToMap = _.where(MyListStore.gifts, { wisher: UserStore.activeChild.id }) ? _.where(MyListStore.gifts, { wisher: UserStore.activeChild.id }).map(g => g._id) : [];
        } else {
            giftsToMap = MyListStore.myList.map(g => g._id);
        }
        if (ranks && ranks.length) {
            ranks.forEach((rank, i) => {
                let currentRanks = this.state.startingRanks;
                if (giftsToMap.includes(rank)) {
                    currentRanks[rank] = i + 1;
                }
                this.setState({ currentChildView: UserStore.childViewActive, rankModalOpen: false, ranksDoneChild: true, ranksDone: false, startingRanks: currentRanks, ranks: currentRanks });
            });
        }
        else {
            this.setState({ currentChildView: UserStore.childViewActive, rankModalOpen: false, ranksDoneChild: true, ranksDone: false, startingRanks: {}, ranks: {} });
        }

    }

    determineRankingGroup() {
        const { UserStore, FamilyStore, MyListStore } = this.props;
        const user = UserStore.childViewActive ? _.findWhere(UserStore.children, { _id: UserStore.activeChild.id }) : UserStore.user;
        const ranks = user.listRank.get(FamilyStore.activeGroupId);
        let giftsToMap = [];
        if (UserStore.childViewActive) {
            giftsToMap = _.where(MyListStore.gifts, { wisher: UserStore.activeChild.id }) ? _.where(MyListStore.gifts, { wisher: UserStore.activeChild.id }).map(g => g._id) : [];
        } else {
            giftsToMap = MyListStore.myList.map(g => g._id);
        }
        let currentRanks = {};
        if (ranks && ranks.length) {
            ranks.forEach((rank, i) => {
                if (giftsToMap.includes(rank)) {
                    currentRanks[rank] = i + 1;
                }
            });
            this.setState({ ranksDoneForGroupChange: true, startingRanks: currentRanks, ranks: currentRanks, currentGroup: FamilyStore.activeGroupId });
        }
        else {
            this.setState({ ranksDoneForGroupChange: true, startingRanks: {}, ranks: {}, currentGroup: FamilyStore.activeGroupId });
        }
    }

    // deleteAlbum(albumName) {
    //     var albumKey = encodeURIComponent(albumName) + "/";
    //     s3.listObjects({ Prefix: albumKey }, function(err, data) {
    //       if (err) {
    //         return alert("There was an error deleting your album: ", err.message);
    //       }
    //       var objects = data.Contents.map(function(object) {
    //         return { Key: object.Key };
    //       });
    //       s3.deleteObjects(
    //         {
    //           Delete: { Objects: objects, Quiet: true }
    //         },
    //         function(err, data) {
    //           if (err) {
    //             return alert("There was an error deleting your album: ", err.message);
    //           }
    //           alert("Successfully deleted album.");
    //           this.listAlbums();
    //         }
    //       );
    //     });
    //   }

    // deletePhoto(albumName, photoKey) {
    //     s3.deleteObject({ Key: photoKey }, function(err, data) {
    //       if (err) {
    //         return alert("There was an error deleting your photo: ", err.message);
    //       }
    //       alert("Successfully deleted photo.");
    //       this.viewAlbum(albumName);
    //     });
    //   }

    // addPhoto(albumName) {
    //     var files = document.getElementById("photoupload").files;
    //     if (!files.length) {
    //       return alert("Please choose a file to upload first.");
    //     }
    //     var file = files[0];
    //     var fileName = file.name;
    //     var albumPhotosKey = encodeURIComponent(albumName) + "/";

    //     var photoKey = albumPhotosKey + fileName;

    //     // Use S3 ManagedUpload class as it supports multipart uploads
    //     var upload = new AWS.S3.ManagedUpload({
    //       params: {
    //         Bucket: albumBucketName,
    //         Key: photoKey,
    //         Body: file,
    //         ACL: "public-read"
    //       }
    //     });

    //     var promise = upload.promise();

    //     promise.then(
    //       function(data) {
    //         alert("Successfully uploaded photo.");
    //         this.viewAlbum(albumName);
    //       },
    //       function(err) {
    //         return alert("There was an error uploading your photo: ", err.message);
    //       }
    //     );
    //   }

    // viewAlbum(albumName) {
    //     let thisSelf = this;
    //     var albumPhotosKey = encodeURIComponent(albumName) + "/";
    //     s3.listObjects({ Prefix: albumPhotosKey }, function(err, data) {
    //       if (err) {
    //         return alert("There was an error viewing your album: " + err.message);
    //       }
    //       // 'this' references the AWS.Response instance that represents the response
    //       var href = this.request.httpRequest.endpoint.href;
    //       var bucketUrl = href + albumBucketName + "/";

    //       var photos = data.Contents.map(function(photo) {
    //         var photoKey = photo.Key;
    //         var photoUrl = bucketUrl + encodeURIComponent(photoKey);
    //         return [
    //           <span>,
    //           <div>,
    //           <img style="width:128px;height:128px;" src={' + photoUrl + '}/>,
    //           </div>,
    //           <div>,
    //           <span onclick={thisSelf.deletePhoto('" + albumName + "','" + photoKey + "')}>,
    //           "X",
    //           </span>,
    //           <span>,
    //           {photoKey.replace(albumPhotosKey, "")},
    //           </span>,
    //           </div>,
    //           </span>
    //         ].join('\n');
    //       });
    //       var message = photos.length
    //         ? <p>Click on the X to delete the photo</p>
    //         : <p>You do not have any photos in this album. Please add photos.</p>;
    //       var htmlTemplate = [
    //         "<h2>",
    //         "Album: " + albumName,
    //         "</h2>",
    //         message,
    //         "<div>",
    //         photos.join('\n'),
    //         "</div>",
    //         '<input id="photoupload" type="file" accept="image/*">',
    //         '<button id="addphoto" onclick="addPhoto(\'' + albumName + "')\">",
    //         "Add Photo",
    //         "</button>",
    //         '<button onclick="listAlbums()">',
    //         "Back To Albums",
    //         "</button>"
    //       ];
    //       document.getElementById("app").innerHTML = htmlTemplate.join('\n');
    //     });
    //   }

    // createAlbum(albumName) {
    //     albumName = albumName.trim();
    //     if (!albumName) {
    //       return alert("Album names must contain at least one non-space character.");
    //     }
    //     if (albumName.indexOf("/") !== -1) {
    //       return alert("Album names cannot contain slashes.");
    //     }
    //     var albumKey = encodeURIComponent(albumName);
    //     s3.headObject({ Key: albumKey }, function(err, data) {
    //       if (!err) {
    //         return alert("Album already exists.");
    //       }
    //       if (err.code !== "NotFound") {
    //         return alert("There was an error creating your album: " + err.message);
    //       }
    //       s3.putObject({ Key: albumKey }, function(err, data) {
    //         if (err) {
    //           return alert("There was an error creating your album: " + err.message);
    //         }
    //         alert("Successfully created album.");
    //         this.viewAlbum(albumName);
    //       });
    //     });
    //   }

    // listAlbums() {
    //   console.log("L:ISTING ALBUMS");
    //   let self = this;

    //     s3.listObjects({ Delimiter: "/" }, function(err, data) {
    //       if (err) {
    //         return alert("There was an error listing your albums: " + err.message);
    //       } else {
    //          console.log(data);
    //          self.setState({albums: data.Contents.map(album =>  <div>{album.Key}</div>)});      

    //       }
    //     });
    //   }  

    onSubmitRankClick() {
        const { FamilyStore, UserStore, AuthStore } = this.props;
        let found = [];
        let notUniq = false;
        Object.keys(this.state.ranks).forEach((key) => {
            if (found.includes(this.state.ranks[key])) {
                notUniq = true;
            } else {
                found.push(this.state.ranks[key]);
            }
        });
        if (notUniq) {
            message.warn("Must have unique rankings");
        }
        else {
            this.setState({ rankModalOpen: false });
            let ranks = _.sortBy(Object.keys(this.state.ranks), key => Number(this.state.ranks[key]));
            let userId = UserStore.childViewActive ? UserStore.activeChild.id : UserStore.user._id;
            let token = UserStore.childViewActive ? UserStore.activeChild.jwt : AuthStore.userAuth.auth.accessToken;
            updateUserGiftRanks(userId, token, FamilyStore.activeGroupId, ranks).then((res) => {
                if (res && res.status === 200) {
                    message.success("Ranks updated!");
                    UserStore.refreshUser(AuthStore.userAuth.auth.accessToken, FamilyStore.activeGroupId);
                }
                else {
                    message.error("Problem updating ranks...");
                }
            });
        }
    }

    rankChanged(gift, value) {
        let ranks = _.clone(this.state.ranks);
        ranks[gift._id] = value;
        this.setState({ ranks });
    }

    clearGiftRank(giftId) {
        let ranks = _.clone(this.state.ranks);
        delete ranks[giftId];
        this.setState({ ranks });
    }

    render() {
        const { MyListStore, UserStore, FamilyStore } = this.props;
        let notes;
        let giftsToMap = [];
        if (UserStore.childViewActive && _.where(MyListStore.gifts, { wisher: UserStore.activeChild.id }) && _.where(MyListStore.gifts, { wisher: UserStore.activeChild.id }).length && !this.state.ranksDoneChild) {
            this.determineRankingChild();
        }
        if (!UserStore.childViewActive && MyListStore.myList.length && !this.state.ranksDone) {
            this.determineRanking();
        }
        if (UserStore.childViewActive !== this.state.currentChildView && !MyListStore.loading && MyListStore.loaded) {
            if (!UserStore.childViewActive) {
                this.setState({ currentChildView: UserStore.childViewActive });
                this.determineRanking();
            }
        }
        if (UserStore.childViewActive) {
            if (UserStore.activeChild.id) {
                notes = _.findWhere(UserStore.children, { _id: UserStore.activeChild.id }) ? _.findWhere(UserStore.children, { _id: UserStore.activeChild.id }).notes : [];
                giftsToMap = _.where(MyListStore.gifts, { wisher: UserStore.activeChild.id }) ? _.where(MyListStore.gifts, { wisher: UserStore.activeChild.id }) : [];
                if (!this.state.ranksDoneChild && giftsToMap.length) {
                    this.determineRankingChild();
                }
            } else {
                notes = [];
            }
        } else {
            notes = UserStore.user.notes;
            giftsToMap = MyListStore.myList;
            if (this.state.ranksDoneChild) {
                this.determineRankingChild();
                this.setState({ ranksDoneChild: false });
            }
        }
        if (FamilyStore.activeGroupId && (FamilyStore.activeGroupId !== this.state.currentGroup)) {
            if (!this.state.ranksDoneForGroupChange) {
                this.setState({ ranks: {} });
                this.determineRankingGroup();
            }
            if (UserStore.childViewActive) {
                this.setState({ ranksDoneChild: false });
            }
        } else {
            if (this.state.ranksDoneForGroupChange) {
                this.determineRankingGroup();
                this.setState({ ranksDoneForGroupChange: false });
            }
        }
        const menuButtonStyle = {
            backgroundColor: "rgba(0,0,0,0)",
            border: 'none',
            textAlign: 'center',
            padding: '15px',
            cursor: 'pointer',
            backgroundColor: UserStore.theme().icons || "white",
            color: UserStore.theme().font2 || "black",
            borderRadius: '40px',
            marginLeft: "5px",
            marginRight: "5px",
            marginTop: "5px"
        };
        return (
            <div style={{backgroundColor: UserStore.theme().appBackground || "white"}}>
                {/* <Button onClick={this.listAlbums.bind(this)}>List Albums</Button> 
               <Button onClick={this.createAlbum.bind(this, "TEst-Album")}>Create Album</Button> 
               <Button onClick={this.addPhoto.bind(this, "TEst-Album")}>Add photo</Button> 
               {this.state.albums} */}
                <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                    isOpen={this.state.rankModalOpen}
                    onRequestClose={this.determineRanking.bind(this)}>
                    <div style={{ textAlign: "center" }}>
                        <h2 style={{ color: "#3CAEA3" }} className="AppFont">Rank your gifts in order of preference</h2>
                        <h4 style={{ color: "#3CAEA3" }} className="AppFont">your group admin has set the max rank to: {FamilyStore.activeGroup.settings.rankingMax}</h4>

                        {giftsToMap.map(gift => <div className="AppFont" style={{ textAlign: "center", padding: "7px" }}>
                            {determineCategoryIcon(gift.category, 15)} {gift.title} <span>
                                <InputNumber max={Math.min(giftsToMap.length, FamilyStore.activeGroup.settings.rankingMax)} onChange={this.rankChanged.bind(this, gift)}
                                    defaultValue={this.state.startingRanks[gift._id] || null} value={this.state.ranks[gift._id] || null} min={1} />
                                <span style={{ fontSize: 12, marginLeft: "7px" }}><Button size="small" onClick={this.clearGiftRank.bind(this, gift._id)}>Clear</Button></span>
                            </span>
                        </div>)}
                        <Button className="AppFont btn-submit" style={{ color: "white", backgroundColor: "#F6D55C" }} onClick={this.onSubmitRankClick.bind(this)} size="large"><Icon type="check-circle" /> Submit</Button>
                        <Button className="AppFont btn-cancel" style={{ color: "white", backgroundColor: "#ED553B" }} onClick={() => { this.setState({ rankModalOpen: false, ranks: this.state.startingRanks }) }} size="large"><Icon type="close" /> Cancel</Button>
                    </div>
                </Modal>

                {UserStore.user.isWelcomeMessageDismissed ?
                    <div>
                        <div style={{position: "fixed", zIndex: 999, width: "100%"}}>
                            <div style={{float: 'left'}}><AddGift divStyle={menuButtonStyle} /></div>
                            <div style={{float: 'left'}}><AddNote divStyle={menuButtonStyle}  /></div>
                                {FamilyStore.activeGroup && FamilyStore.activeGroup.settings.allowRankings ?
                                    <div style={{float: 'left'}}>
                                        <div style={menuButtonStyle} onClick={() => { this.props.MyListStore.myList.length == 0 ? message.warn("You must have gifts listed to rank them!") : this.setState({ rankModalOpen: true }) }} size="large">
                                            <Icon style={{fontSize: "14pt", paddingRight: "4px"}} type="star" />Rank Gifts
                                    </div>
                                    </div> : null}
                        </div>
                        <div style={{ color: "white", fontSize: "30pt", paddingTop: "32px" }} className="AppFont">
                            {/* <Avatar size={90} shape="circle" src={UserStore.user.imageLink} /> */}
                            <img alt="" className="profile-img" style={{ borderRadius: "80px", width: "150px", height: "150px" }} />
                            <br />
                            <span>{UserStore.childViewActive ? `${UserStore.activeChild.name}'s` : "Your"} List {UserStore.user.groups.length > 1 ? "for " + FamilyStore.activeGroupName : ""}</span>
                        </div>
                        {notes.length === 0 ? null : <Notes notes={notes} />}
                        {FamilyStore.activeGroupId === "" ? "" : MyListStore.loading ? <Icon type="loading" /> :
                            (MyListStore.myList.length > 0 ?
                                <div style={{ marginTop: "30px" }}>
                                    <GiftCards sort={FamilyStore.activeGroup && FamilyStore.activeGroup.settings.allowRankings} ranks={this.state.ranks} gifts={UserStore.childViewActive ? _.where(MyListStore.gifts, { wisher: UserStore.activeChild.id }) : MyListStore.myList} />
                                </div>
                                : <h1 className="AppFont" style={{ color: "white", marginTop: "20%" }}>No gifts found</h1>)}
                    </div>
                    : <WelcomeModal />}
                {/* <div style={{ position: 'fixed', bottom: 0, color: "white", backgroundColor: "rgba(0,0,0,0.4)" }}>As an Amazon Associate list.gifts earns from qualifying purchases.</div> */}
            </div>
        )
    }
}
export default MyList;