const themes = {
    default: {
        title: "Default",
        appBackground: "#a1e0ff",
        background: "white",
        darkBackground: "#144C80",
        header: "#001529",
        icons: "#1890ff",
        font: "#1890ff",
        font2: "white",
        btnInfo: "#1890ff",
        btnDanger: "rgb(237, 85, 59)",
        btnSuccess: "#1dd17a",
        btnWarn: "#F6D55C"
    },
    xmas: {
        title: "Christmas",
        appBackground: "#16A155",
        background: "#1dd17a",
        darkBackground: "#A13127",
        header: "#1dd17a",
        icons: "#ED5E51", 
        font: "rgb(237, 85, 59)",
        font2: "white",
        btnInfo: "#1890ff",
        btnDanger: "rgb(237, 85, 59)",
        btnSuccess: "#1dd17a",
        btnWarn: "#F6D55C"
    },
    mint: {
        title: "Mint Chocolate Chip",
        appBackground: "#94E0CC",
        background: "white", //settings drawer
        darkBackground: "#20617A",
        header: "#6999AC",
        icons: "#AD6170", 
        font: "#AD6170",
        font2: "white",
        btnInfo: "#1890ff",
        btnDanger: "#E46A5E",
        btnSuccess: "#1dd17a",
        btnWarn: "#F6D55C"
    }
};

export const allThemes = themes;

export const appColours = (theme = "default") => {
    return themes[theme];
}