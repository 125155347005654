import React, { Component } from 'react'
import { MobxRouter, startRouter, Route } from 'mobx-router';
//mobx
import { Provider } from 'mobx-react';
import CustomRouter from '../stores/CustomRouter';
//router
import NavViews from '../stores/NavViews'
import FourOhFour from './FourOhFour'
startRouter(NavViews, CustomRouter, {notfound: () => CustomRouter.router.goTo(NavViews.home)});

class CoreApp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        return (
            <Provider store={CustomRouter}>
                <MobxRouter />
            </Provider>
        );
    }
}
export default CoreApp;