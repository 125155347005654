import React, { Component } from 'react';
import './App.css';
import CoreApp from './components/CoreApp';
import MyHeader from './components/MyHeader';
import { observer } from 'mobx-react';
import { getUser } from './api_calls/user';
import Modal from 'react-modal';
import { Layout, Icon, message, Affix } from 'antd';
import { MobxRouter, startRouter, Route } from 'mobx-router';
import _ from "underscore";
//mobx
import { Provider } from 'mobx-react';
import GuestRouter from './stores/GuestRouter';
import CustomRouter from './stores/CustomRouter';
import InvitePage from './components/InvitePage';
//router

import ResetPassword from './components/ResetPassword';
import LandingPageIndex from './components/LandingPageIndex';
import { createLog } from './api_calls/logging';

const LS_KEY = 'mm-login-demo:auth';
Modal.setAppElement('#root')
@observer(["UserStore", "AuthStore", "CustomRouter", "GuestRouter"])
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingModalOpen: true
    };
  }
  componentWillMount() {
    const { UserStore } = this.props;
    // Access token is stored in localstorage
    // console.log("LS: " + localStorage.getItem(LS_KEY));
    const GuestViews = {
      home: new Route({
        path: '/',
        component: <LandingPageIndex />
      }),
      receipts: new Route({
        path: '/receipts',
        component: <LandingPageIndex />
      }),
      groups: new Route({
        path: '/groups',
        component: <LandingPageIndex />
      }),
      events: new Route({
        path: '/events',
        component: <LandingPageIndex />
      }),
      myNotes: new Route({
        path: '/my-notes',
        component: <LandingPageIndex />
      }),
      singleList: new Route({
        path: '/list/:userId',
        component: <LandingPageIndex />
      }),
      reset: new Route({
        path: '/reset-password/:resetLink',
        component: <ResetPassword />,
        onEnter: (route, params, store) => {
          console.log(`entering list with params`, params);
        }
      }),
      invitePage: new Route({
        path: '/invite/:inviteId',
        component: <LandingPageIndex />,
        onEnter: (route, params, store) => {
          console.log(`entering page with params`, params);
        }
      })
    }
    startRouter(GuestViews, GuestRouter, { notfound: () => GuestRouter.router.goTo(GuestViews.home) });
    let auth;
    let self = this;
    const key = _.clone(localStorage.getItem(LS_KEY));
    console.log(key);
    if (key !== null && typeof key !== 'undefined' && key !== "undefined") {
      auth = JSON.parse(key);
      getUser(auth.accessToken).then((user) => {
        const logObj = {
          type: "sessionStart",
          userId: user._id          
        };
        createLog(auth.accessToken, logObj);
        self.props.UserStore.setUser(user).then(() => { self.setState({ loadingModalOpen: false }) })
      });
    } else {
      self.setState({ loadingModalOpen: false });
    }
    this.setState({
      auth
    });
    this.props.AuthStore.authUser(auth)
  }

  handleLoggedIn = auth => {
    let self = this;
    localStorage.setItem(LS_KEY, JSON.stringify(auth));
    this.props.AuthStore.authUser(auth);
    message.success("Successfully logged in!");
    getUser(auth.accessToken).then((user) => {
      self.props.UserStore.setUser(user);
    });
  };

  handleInviteLoggedIn = auth => {
    let self = this;
    localStorage.setItem(LS_KEY, JSON.stringify(auth));
    this.props.AuthStore.authUser(auth);
    message.success("Successfully logged in!");
    getUser(auth.accessToken).then((user) => {
      self.props.UserStore.setUser(user);
      CustomRouter.router.goTo(new Route({
        path: '/invite/'.concat(this.props.GuestRouter.router.params.inviteId),
        component: <InvitePage />,
        onEnter: (route, params, store) => {
          console.log(`entering page with params`, params);
        }
      }));
    });
  };

  handleLoggedOut = () => {
    localStorage.removeItem(LS_KEY);
    GuestRouter.router.goTo(new Route({
      path: '/',
      component: <LandingPageIndex />
    }));
    this.props.UserStore.setUser({ _id: "guest", role: "guest", groups: [], name: "guest" })
    this.setState({ auth: undefined });
  };

  componentDidMount() {
    const jq = document.createElement("script");
    jq.src = "https://code.jquery.com/jquery-3.4.1.min.js"
    jq.async = true;
    document.body.appendChild(jq);
    document.body.style.backgroundColor = "#5fb4de"; //"#a1e0ff";
  }

  render() {
    return (
      <div className="App" style={{ textAlign: "center", width: '100vw' }}>
        <Modal className="loadingModal" style={{
          overlay: {
            backgroundColor: '#0b5284',
            width: "100%",
            height: "100%",
            border: "none"

          }, content: { background: "#0b5284" }
        }} isOpen={this.state.loadingModalOpen
        }>
          <div style={{ background: "#0b5284", textAlign: "center", border: "none" }}>
            <div style={{
              position: "absolute",
              top: "20%",
              background: "#0b5284",
              color: "white",
              fontSize: "20pt",
              textAlign: "center"
            }} className="AppFont">Unwrapping...</div>

            <Icon className="bounce" style={{
              position: "absolute",
              top: "50%",
              background: "#0b5284",
              color: "white",
              fontSize: "80pt"
            }} type="gift" />
          </div>
        </Modal>
        {this.props.UserStore.user.role === "guest" ?
          <Provider store={GuestRouter}>
            <MobxRouter />
          </Provider>
          :
          <div>
            <Layout className="layout">
              <MyHeader onLoggedOut={this.handleLoggedOut} />
            </Layout>
            <CoreApp />
          </div>}
      </div>
    );
  }
}
export default App;