import React, { Component } from 'react'
import { lazy } from 'react';

import IntroContent from "../content/IntroContent.json";
import MiddleBlockContent from "../content/MiddleBlockContent.json";
import AboutContent from "../content/AboutContent.json";
import MissionContent from "../content/MissionContent.json";
import ProductContent from "../content/ProductContent.json";
import ContactContent from "../content/ContactContent.json";

const ContactFrom = lazy(() => import("./ContactForm"));
const ContentBlock = lazy(() => import("./ContentBlock"));
const MiddleBlock = lazy(() => import("./MiddleBlock"));
const Container = lazy(() => import("../common/Container"));
const ScrollToTop = lazy(() => import("../common/ScrollToTop"));


class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container>
        <ScrollToTop />
        <ContentBlock
          type="right"
          first="true"
          title={IntroContent.title}
          content={IntroContent.text}
          button={IntroContent.button}
          icon="gift-ballons.svg"//"gift-box-black.svg"
          id="intro"
        />
        <MiddleBlock
          title={MiddleBlockContent.title}
          content={MiddleBlockContent.text}
          button={MiddleBlockContent.button}
        />
        <ContentBlock
          type="left"
          title={AboutContent.title}
          content={AboutContent.text}
          section={AboutContent.section}
          icon="calendar.svg"
          id="about"
        />
        <ContentBlock
          type="right"
          title={MissionContent.title}
          content={MissionContent.text}
          icon="awesome.svg"
          id="mission"
        />

        <ContentBlock
          type="left"
          title={ProductContent.title}
          content={ProductContent.text}
          icon="well-done.svg"
          id="product"
        />
        {/* <ContactFrom
        title={ContactContent.title}
        content={ContactContent.text}
        id="contact"
      /> */}
      </Container>
    );
  }
}

export default LandingPage;