import React, { Component } from 'react'
class MenuSpoof extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (
            <div style={{float: "right"}}>
                <div style={{ backgroundColor: "black", height: "1px", width: "15px", marginBottom: "5px"}}></div>
                <div style={{ backgroundColor: "black", height: "1px", width: "15px", marginBottom: "5px"}}></div>
                <div style={{ backgroundColor: "black", height: "1px", width: "15px", marginBottom: "5px"}}></div>
            </div>
        );
    }
}

export default MenuSpoof;