import React, { Component } from 'react'
import { useState, Fragment, lazy } from "react";
import { Row, Col, Drawer } from "antd";
import { CSSTransition } from "react-transition-group";
import { withTranslation } from "react-i18next";
import LandingPageIndex from '../LandingPageIndex.js';


import * as S from "./styles";
import LandingPage from '../LandingPage.js';
import RegisterButton from '../RegisterButton.js';
import LoginButton from '../LoginButton.js';
import ForgotPasswordButton from '../ForgotPasswordButton.js';

const SvgIcon = lazy(() => import("../../common/SvgIcon"));
const Button = lazy(() => import("../../common/Button"));


const Header = ({ t }) => {

  const [isNavVisible] = useState(false);
  const [isSmallScreen] = useState(false);
  const [visible, setVisibility] = useState(false);

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const scrollUp = () => {
    const element = document.getElementById("intro");
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };



  const MenuItem = () => {

    const scrollTo = (id) => {
      const element = document.getElementById(id);
      const yOffset = -225;
      const y = element && element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      // element.scrollIntoView({
      //   behavior: "smooth",
      // });
      if (y) {
        window.scrollTo({
          top: y,
          behavior: "smooth",
        });
        setVisibility(false);
      }
    };
    return (
      <Fragment>
        <S.CustomNavLinkSmall to="/" onClick={() => {

          if (window.location.href.includes("terms") || window.location.href.includes("support")) {
            window.location.href = window.location.origin;
          }
          else {
            scrollTo("about");
          }
        }
        }>
          <S.Span>{t("Product")}</S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall to="/" onClick={() => {

          if (window.location.href.includes("terms") || window.location.href.includes("support")) {
            window.location.href = window.location.origin;
          }
          else {
            scrollTo("mission");
          }
        }}>
          <S.Span>{t("Mission")}</S.Span>
        </S.CustomNavLinkSmall>
        {/* <S.CustomNavLinkSmall onClick={() => scrollTo("product")}>
          <S.Span>{t("Product")}</S.Span>
        </S.CustomNavLinkSmall> */}
        <S.CustomNavLinkSmall
          style={{ width: "120px" }}
          onClick={() => scrollTo("contact")}
        >
          <S.Span>
            {/* <Button onClick={() => {}}>{t("Sign Up")}</Button> */}
            <RegisterButton />
          </S.Span>
        </S.CustomNavLinkSmall>
        <S.CustomNavLinkSmall style={{ width: "120px" }}>
          <S.Span>
            {/* <Button color={"#fff"} >{t("Login")}</Button> */}
            <LoginButton />
          </S.Span>
        </S.CustomNavLinkSmall>
      </Fragment>
    );
  };
  console.log(window.location.href);
  // console.log(this.props);
  return (
    <S.Header>
      
      <S.Container style={{maxHeight: "100px"}}>
        <Row type="flex" style={{ alignItems: "flex-start" }} justify="space-between" gutter={20}>
          <S.LogoContainer to="/" onClick={scrollUp} aria-label="homepage">
            <SvgIcon width={90} src="gift2.png" />
          </S.LogoContainer>
          <S.NotHidden>
            <MenuItem />
          </S.NotHidden>
          <S.Burger onClick={showDrawer}>
            <S.Outline />
          </S.Burger>
        </Row>
        <CSSTransition
          in={!isSmallScreen || isNavVisible}
          timeout={350}
          classNames="NavAnimation"
          unmountOnExit
        >
          <Drawer closable={false} visible={visible} onClose={onClose}>
            <Col style={{ marginBottom: "2.5rem" }}>
              <S.Label onClick={onClose}>
                <Col span={12}>
                  <S.Menu style={{ color: "black" }}>Menu</S.Menu>
                </Col>
                <Col span={12}>
                  <S.Outline padding="true" />
                </Col>
              </S.Label>
            </Col>
            <MenuItem />
          </Drawer>
        </CSSTransition>
      </S.Container>
    </S.Header>
  );
};

export default withTranslation()(Header);
