import { types } from "mobx-state-tree"

const Receipt = types.model({
    _id: types.identifier,
    userId: types.string,
    giftId: types.optional(types.string, ""),
    title: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    category: types.optional(types.string, "gift"),
    price: types.optional(types.number, 0),
    link: types.optional(types.string, ""),
    wisher: types.optional(types.string, ""),
    timePurchased: types.optional(types.number, 0),
    groupId: types.optional(types.string, ""),
});

export default Receipt;