import axios from "axios";
import { message } from "antd";
const url = process.env.REACT_APP_BACKEND_URL;

export async function getUser(auth) {
    return axios({
        method: 'get',
        url: url + "/users",
        headers: { Authorization: `Bearer ${auth}` },
    }).then((res) => {
        return res.data;
    }).catch(function (error) {
        message.error("Error logging in! " + error);
    });
}

export async function getKidUserObjects(auth) {
    return axios({
        method: 'get',
        url: url + "/users/kids",
        headers: { Authorization: `Bearer ${auth}` },
    }).then((res) => {
        return res.data;
    }).catch(function (error) {
        message.error("Error logging in! " + error);
    });
}

export async function addKidToGroup(auth, groupId, groupName, userId) {
    return axios({
        method: 'put',
        url: url + "/users/add-kid-to-group",
        headers: { Authorization: `Bearer ${auth}` },
        data: {groupId, groupName, userId}
    }).then((res) => {
        return res.data;
    }).catch(function (error) {
        message.error("Error adding child to group! " + error);
    });
}

export async function editGroups(auth, userId, groups) {
    return axios({
        method: 'put',
        url: url + "/users/edit-groups",
        headers: { Authorization: `Bearer ${auth}` },
        data: {groups, userId}
    }).then((res) => {
        return res.data;
    }).catch(function (error) {
        message.error("Error adding child to group! " + error);
    });
}

export async function sendResetEmail(email) {  
    return axios({
        method: 'post',
        url: url + "/auth/reset",
        data: {email}
    }).then((res) => {
        message.success("Email with reset link sent!");
        return res.data;
    }).catch(function (error) {
        message.error("Error sending email! " + error);
    });
}

export async function createUser(
    name,
    email,
    hashPass,
    family
) {
    return axios.post(url + "/users", {
        name,
        email,
        hashPass,
        family
    }, {
    })
        .then(function (response) {
            message.success("Success, user registered!");
            console.log(response);
        })
        .catch(function (error) {
            if (error.response.status === 409) {
                message.error("User with that email already exists!");
            } else {
                message.error("Error making user.");
            }
        });
}

export async function updateProfileImageLocation(imageFileLocation, userId, auth) {
    return axios.put(url + "/users/upload-image/" + userId, { imageFileLocation }, {
        headers: { Authorization: `Bearer ${auth}` }
    }).then(function (response) {
        // message.success("Success, image uploaded!", 0.7);
        console.log(response);
    })
        .catch(function (error) {
            console.log(error);
            // message.error("Error uploading: " + error);
        });
}

export async function createGroup(name, users, creator, auth) {
    return axios.post(url + "/users/create-group", { name, users, creator }, {
    headers: { Authorization: `Bearer ${auth}` }
    }).then(function (response) {
        message.success("Success, group created!", 0.7);
        console.log(response);
    })
    .catch(function (error) {
        message.error("Error creating group: " + error);
    });

}

export async function resetPassword(newPassHash, auth) {
    console.log(auth);
    return axios({
        method: 'put',
        url: url + "/auth/reset-password",
        data: {
            newPassHash
        },
        headers: { Authorization: `Bearer ${auth}` },
    }).then(function (response) {
        console.log(response);
        if (response.data.status === 200) {
            message.success("Success, password changed!", 0.7);
            return { status: 200 };
        }
        else {
            message.error("Error changing password");
        }
    })
        .catch(function (error) {
            console.log(error);
            if (String(error).includes(403)) {
                message.error("Error: current password entered is incorrect");
            }
            else {
                message.error("Error changing password: " + error);
            }
        });
}

export async function changePassword(userId, passHash, newPassHash, auth) {
    console.log(auth);
    return axios({
        method: 'put',
        url: url + "/auth/change-password/" + userId,
        data: {
            passHash,
            newPassHash
        },
        headers: { Authorization: `Bearer ${auth}` },
    }).then(function (response) {
        console.log(response);
        if (response.data.status === 200) {
            message.success("Success, password changed!", 0.7);
            return { status: 200 };
        }
        else {
            message.error("Error changing password");
        }
    })
        .catch(function (error) {
            console.log(error);
            if (String(error).includes(403)) {
                message.error("Error: current password entered is incorrect");
            }
            else {
                message.error("Error changing password: " + error);
            }
        });
}

export async function updateUserBirthday(userId, accessToken, birthDay, birthMonth) {
    return axios.put(url + "/users/update-bday/" + userId, { birthDay, birthMonth }, {
        headers: { Authorization: `Bearer ${accessToken}` }
    }).then(function (response) {
        message.success("Success, bday uploaded!", 0.7);
        console.log(response);
    })
        .catch(function (error) {

            message.error("Error uploading: " + error);
        });

}

export async function updateUser(userId, accessToken, mongoUpdate) {
    return axios.put(url + "/users/update/" + userId, {mongoUpdate}, {
        headers: { Authorization: `Bearer ${accessToken}` }
    }).then(function (response) {
        return response;
    })
        .catch(function (error) {
            return error;
        });

}

export async function updateUserGiftRanks(userId, accessToken, groupId, ranks) {
    return axios.put(url + "/users/update-ranks/" + userId, {groupId, ranks}, {
        headers: { Authorization: `Bearer ${accessToken}` }
    }).then(function (response) {
        return response;
    })
        .catch(function (error) {
            return error;
        });

}


export async function findName(userId) {
    return axios({
        method: 'get',
        url: url + "/users/name/" + userId,
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}


export async function findNotes(userId) {
    return axios({
        method: 'get',
        url: url + "/users/notes/" + userId,
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}

export async function findPrivateNotes(userId) {
    console.log("findingPrivatnotres");
    return axios({
        method: 'get',
        url: url + "/users/private-note/" + userId,
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}

export async function findImageLink(userId) {
    return axios({
        method: 'get',
        url: url + "/users/image/" + userId,
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}

export async function getGroupName(groupId) {
    return axios({
        method: 'get',
        url: url + "/users/group-name/" + groupId,
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}

export async function getGroup(groupId,auth) {
    return axios({
        method: 'get',
        url: url + "/groups/" + groupId,
        headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}

export async function deleteNote(userId, key) {
    return axios({
        method: 'delete',
        url: url + "/users/note/" + userId,
        data: { note: key },
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}

export async function deletePrivateNote(userId, key) {
    return axios({
        method: 'delete',
        url: url + "/users/private-note/" + userId,
        data: { note: key },
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}

export async function joinGroup(userId, accessToken, groupId, groupName) {
    return axios.put(url + "/users/join-group/" + groupId, { userId, groupName }, {
        // headers: { Authorization: `Bearer ${accessToken}` }
    }).then(function (response) {
        message.success("Success, group joined!", 0.7);
        console.log(response);
    })
        .catch(function (error) {
            message.error("Error joining group: " + error);
        });

}

export async function addNote(userId, note) {
    return axios({
        method: 'put',
        url: url + "/users/note/" + userId,
        data: { note: note },
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}

export async function addPersonalNote(userId, note) {
    return axios({
        method: 'put',
        url: url + "/users/private-note/" + userId,
        data: { privateNote: note },
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        return res.data;
    });
}

export async function addKidAccount(userId, kidData) {
    return axios({
        method: 'put',
        url: url + "/users/add-kid/" + userId,
        data: kidData,
        // headers: { Authorization: `Bearer ${auth}` }

    }).then(res => {
        message.success("Success, kid added!", 0.7);
        console.log(res);
        return res.data;
    })
        .catch(function (error) {
            message.error("Error adding kid: " + error);
        });
}

export async function getFamilyMembers(family, auth) {
    if (family) {
        return axios({
            method: 'get',
            url: url + "/users/family/" + family,
            headers: { Authorization: `Bearer ${auth}` }

        }).then(res => {
            return res.data;
        }) .catch(function (error) {
            message.error("Error getting group members: " + error);
        });
    }
}

export async function setChildOptionsVisible(userId, isVisible) {
    return axios({
        method: 'put',
        url: url + "/users/set-child-options-visibility/" + userId,
        data: { isVisible },
        // headers: { Authorization: `Bearer ${auth}` }
    }).then(res => {
        return res.data;
    });
}


