import React, { Component } from 'react';
import Modal from 'react-modal';
import { observer } from "mobx-react";
import { createReceipt } from "../api_calls/receipt.js";
import _ from "underscore";
import { DatePicker, Input, Button, Icon, InputNumber, message } from 'antd';

Modal.setAppElement('#root');
@observer(["UserStore", "FamilyStore", "MyListStore", "AuthStore"])
class AddReceiptModal extends Component {
    constructor(props) {
        super(props);
        this.state = { submittingRct: false };
        this.onSubmit = this.onSubmit.bind(this);
    }
    onSubmit() {
        this.setState({ submittingRct: true });
        let newRct = _.clone(this.state);
        delete newRct.submittingRct;
        newRct.userId = this.props.UserStore.childViewActive ? this.props.UserStore.activeChild.id : this.props.UserStore.user._id;
        newRct._id = newRct.userId.concat(new Date().getTime());
        const token = this.props.AuthStore.userAuth.auth.accessToken;
        if(!newRct.timePurchased) {
            newRct.timePurchased = new Date().getTime();
        }
        if (!newRct.title) {
            message.warn("Must have a title!");
            this.setState({ submittingRct: false });
        }
        else if (!newRct.wisher) {
            message.warn("Must have a giftee!");
            this.setState({ submittingRct: false });
        }
        else {
            createReceipt(token, newRct).then((res) => {
                if (res && res.status && (res.status === 200)) {                   
                    this.props.MyListStore.loadReceipts(token);
                }
               
                this.setState({ submittingRct: false });
                this.props.closeModalCallback();
            });
        }
    }
    render() {
        return (<Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
            isOpen={this.props.modalOpen}
            onRequestClose={() => { (this.state.submittingRct ? console.log("tried to close") : this.props.closeModalCallback()) }}>
            <div style={{ textAlign: "center" }}>
                <h2 style={{ color: "white" }} className="AppFont">New Receipt</h2>
                <div style={{ display: "inline-grid" }}>
                    Title <Input onChange={(e) => { this.setState({ title: e.target.value }) }} placeholder="Title" />
                    <br />
                Description <Input onChange={(e) => { this.setState({ description: e.target.value }) }} placeholder="Description" />
                    <br />
                    For <Input onChange={(e) => { this.setState({ wisher: e.target.value }) }} placeholder="For" />
                    <br />
                    Price <InputNumber onChange={(value) => { this.setState({ price: value }) }} placeholder="Price" />
                    <br />
                    Date Bought <DatePicker onChange={(value) => { this.setState({ timePurchased: value ? value.unix() * 1000 : null }) }} />
                    <br />
                    <Button className="AppFont btn-submit" onClick={this.onSubmit} size="large"><Icon type="check-circle" /> Submit Receipt</Button>
                    <Button className="AppFont btn-cancel" onClick={() => { this.props.closeModalCallback() }} size="large"><Icon type="close" /> Cancel</Button>
                </div>
            </div>
        </Modal>

        );
    }
}

export default AddReceiptModal;