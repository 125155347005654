import { types } from "mobx-state-tree"

const Gift = types.model({
    _id: types.identifier,
    title: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    category: types.optional(types.string, "gift"),
    price: types.optional(types.number, 0),
    link: types.optional(types.string, ""),
    imageLink: types.optional(types.string, ""),
    wisher: types.optional(types.string, ""),
    purchaser: types.optional(types.string, ""),
    purchaserName: types.optional(types.string, ""),
    timeWishedFor: types.optional(types.number, 0),
    timePurchased: types.optional(types.number, 0),
    family: types.optional(types.string, ""),
    groups: types.optional(types.array(types.string), [])
});

export default Gift;