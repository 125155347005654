import { observable, action, computed } from 'mobx';

class AuthStore {

    @observable userAuth = { auth: [] };

    // Change View
    @action authUser = (authVar) => {
        this.userAuth.auth = authVar;
    }

    @computed get isUserAuthed() {
        return this.userAuth.auth;
    }
}

const store = new AuthStore();
export default store;