import React, { Component } from 'react'
import { FaTicketAlt, FaUtensils, FaDAndD, FaCampground, FaRegGem, FaGamepad, FaChess, FaTv, FaBlender, FaBath, FaHome, FaTableTennis } from 'react-icons/fa'
import { Icon } from 'antd';

export const colourMap = {
    "1" : "gold",
    "2": "#1890ff",
    // "3": "bronze"
};

export const determineCategoryIcon = (category, chosenFontSize = 70) => {
    let icon;
    let iconFontSize = String(chosenFontSize).concat("pt");
    switch (category) {
        case ("books"):
            icon = <Icon type="book" style={{ fontSize: iconFontSize }} />;
            break;
        case ("camping"):
            icon = <FaCampground style={{ fontSize: iconFontSize }} />;
            break;
        case ("clothing"):
            icon = <Icon type="skin" style={{ fontSize: iconFontSize }} />;
            break;
        case ("tools"):
            icon = <Icon type="tool" style={{ fontSize: iconFontSize }} />;
            break;
        case ("art"):
            icon = <Icon type="picture" style={{ fontSize: iconFontSize }} />;
            break;
        case ("food"):
            icon = <FaUtensils style={{ fontSize: iconFontSize }} />;
            break;
        case ("appliances"):
            icon = <FaBlender style={{ fontSize: iconFontSize }} />;
            break;
        case ("cosmetics"):
            icon = <FaBath style={{ fontSize: iconFontSize }} />;
            break;
        case ("technology"):
            icon = <FaTv style={{ fontSize: iconFontSize }} />;
            break;
        case ("household"):
            icon = <FaHome style={{ fontSize: iconFontSize }} />;
            break;
        case ("board games"):
            icon = <FaChess style={{ fontSize: iconFontSize }} />;
            break;
        case ("video games"):
            icon = <FaGamepad style={{ fontSize: iconFontSize }} />;
            break;
        case ("jewelry"):
            icon = <FaRegGem style={{ fontSize: iconFontSize }} />;
            break;
        case ("gift cards"):
            icon = <Icon type="credit-card" style={{ fontSize: iconFontSize }} />;
            break;
        case ("tickets"):
            icon = <FaTicketAlt style={{ fontSize: iconFontSize }} />;
            break;
        case ("d&d"):
            icon = <FaDAndD style={{ fontSize: iconFontSize }} />;
            break;
        case ("toys"):
            icon = <FaTableTennis style={{ fontSize: iconFontSize }} />;
            break;
        default: icon = <Icon type="gift" style={{ fontSize: iconFontSize }} />;
    }
    return icon;
}