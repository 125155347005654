import React from 'react';
import { Route } from 'mobx-router';
//components
import MyList from '../components/MyList';
import SingleList from '../components/SingleList';
import InvitePage from '../components/InvitePage';
import Groups from '../components/Groups';
import Events from '../components/Events';
import MyNotes from '../components/MyNotes';
import BoughtHistory from '../components/BoughtHistory';


const NavViews = {
  home: new Route({
    path: '/',
    component: <MyList />
  }),
  groups: new Route({
    path: '/groups',
    component: <Groups />
  }),
  events: new Route({
    path: '/events',
    component: <Events />
  }),
  myNotes: new Route({
    path: '/my-notes',
    component: <MyNotes />
  }),
  receipts: new Route({
    path: '/receipts',
    component: <BoughtHistory />
  }),
  singleList: new Route({
    path: '/list/:userId',
    component: <SingleList />,
    onEnter: (route, params, store) => {
      console.log(`entering list with params`, params);
    }
  }),
  invitePage: new Route({
    path: '/invite/:inviteId',
    component: <InvitePage />,
    onEnter: (route, params, store) => {
      console.log(`entering page with params`, params);
    }
  })
 
};
export default NavViews;