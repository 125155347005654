import React, { Component } from 'react';
import Modal from 'react-modal';
import { observer } from "mobx-react";
import { DatePicker, Input, Button, Icon, InputNumber, message } from 'antd';
import { editReceipt } from '../api_calls/receipt.js';
import _ from "underscore";
import moment from "moment";
import MyListStore from '../stores/MyListStore.js';

Modal.setAppElement('#root')
@observer(["UserStore", "FamilyStore", "MyListStore", "AuthStore"])
class EditReceiptModal extends Component {
    constructor(props) {
        super(props);
        this.state = { submittingEdits: false };
        this.onSubmitEdits = this.onSubmitEdits.bind(this);
    }
    componentDidMount() {


    }

    onSubmitEdits() {
        console.log(this.state);
        let clone = _.clone(this.state);
        let propsClone = _.clone(this.props.rct);
        delete clone.submittingEdits;
        console.log(_.extend(propsClone, clone));
        let newRct = _.extend(propsClone, clone);
        let rctId = propsClone._id;
        delete newRct._id;
        const token = this.props.UserStore.childViewActive ? this.props.UserStore.activeChild.jwt : this.props.AuthStore.userAuth.auth.accessToken;
        this.props.closeModalCallback();
        editReceipt(rctId, token, { $set: newRct }).then((res) => {
            console.log(res);
            if (res.status && (res.status === 200)) {
                message.success("Receipt updated!");
                this.props.MyListStore.loadReceipts(token);
            }
            else {
                message.error("Problem updating receipt...");
            }
        });
    }

    render() {
        const { FamilyStore } = this.props;
        let wisher = {};
        let title, price = "";
        let giftFor, desc = "";
        let boughtDate = null;
        if (this.props.rct) {
            wisher = this.props.UserStore.getUser(this.props.rct.wisher) || this.props.rct.wisher;
            title = this.props.rct.title;
            price = this.props.rct.price;
            boughtDate = moment(this.props.rct.timePurchased);
            desc = this.props.rct.description;
            giftFor = _.findWhere(FamilyStore.family, { _id: this.props.rct.wisher }) ? _.findWhere(FamilyStore.family, { _id: this.props.rct.wisher }).name :
                this.props.rct.wisher;
        }

        return (<Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
            isOpen={this.props.modalOpen}
            onRequestClose={() => { (this.state.submittingEdits ? console.log("tried to close") : this.props.closeModalCallback()) }}>
            <div style={{ textAlign: "center" }}>
                <h2 style={{ color: "white" }} className="AppFont">Gift For {wisher ? wisher.name || wisher : ""}</h2>
                <div style={{ display: "inline-grid" }}>
                    Title <Input defaultValue={title} onChange={(e) => { this.setState({ title: e.target.value }) }} placeholder="Title" />
                    <br />
                    Description <Input defaultValue={desc} onChange={(e) => { this.setState({ description: e.target.value }) }} placeholder="Description" />
                    <br />
                        For <Input defaultValue={giftFor} onChange={(e) => { this.setState({ wisher: e.target.value }) }} placeholder="For" />
                    <br />
                        Price <InputNumber defaultValue={price} onChange={(value) => { this.setState({ price: value }) }} placeholder="Price" />
                    <br />
                        Date Bought <DatePicker onChange={(value) => { this.setState({ timePurchased: value ? value.unix() * 1000 : null }) }} defaultValue={boughtDate} />
                    <br />
                    <Button className="AppFont btn-submit" onClick={this.onSubmitEdits} size="large"><Icon type="check-circle" /> Submit Edits</Button>
                    <Button className="AppFont btn-cancel" onClick={() => { this.props.closeModalCallback() }} size="large"><Icon type="close" /> Cancel</Button>
                </div>
            </div>
        </Modal>
        );
    }
}

export default EditReceiptModal;