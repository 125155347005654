import React, { Component } from 'react'
import { message, Button, Icon, Input, InputNumber, Menu, Dropdown, Checkbox } from 'antd'
import { FaTicketAlt, FaUtensils, FaDAndD, FaCampground, FaRegGem, FaGamepad, FaChess, FaTv, FaBlender, FaBath, FaHome, FaTableTennis } from 'react-icons/fa'
import Modal from 'react-modal'
import { observer } from 'mobx-react';
import { createGift, getImage, addImage } from '../api_calls/gift';
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const listOfApprovedHosts = ["www.chapters.indigo.ca", "www.bestbuy.ca"];

Modal.setAppElement('#root')
@observer(['AuthStore', 'UserStore', 'MyListStore', 'FamilyStore'])
class AddGift extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            description: "",
            link: "",
            category: "gift",
            price: 0,
            modalOpen: false,
            submittingNewGift: false,
            dropdownDisplay: <span><Icon type="gift" style={{ fontSize: "35pt" }} /> <Icon type="down" /></span>,
            showCheckboxes: false,
            groupCheckboxes: <div></div>,
            checkedList: [],
            indeterminate: true,
            checkAll: false,
            plainOptions: []
        };
        this.onSubmitClick = this.onSubmitClick.bind(this);      
        this.onChange = this.onChange.bind(this);
        this.onCheckAllChange = this.onCheckAllChange.bind(this);
    }

    componentDidMount() {
        const { UserStore, FamilyStore } = this.props;
        let groups = UserStore.user.groups;
        
        let plainOptions = [];
        console.log("mounting");
        let checkedList = groups && groups.length ? [FamilyStore.activeGroupName] : [];
        let checkedListIds = groups && groups.length ? [FamilyStore.activeGroupId] : [];
        this.setState({ checkedList, checkedListIds });
        if (groups && groups.length > 1) {
            groups.forEach(group => {
                plainOptions.push(group.name);
            });
            this.setState({
                showCheckboxes: true, plainOptions
            });
        }
    }

    onSubmitClick() {
        console.log("submitting new gift...");
        const { UserStore, FamilyStore, AuthStore, MyListStore } = this.props;
        let auth = UserStore.childViewActive ? UserStore.activeChild.jwt : AuthStore.userAuth.auth.accessToken;
        const { title, description, link, price, category } = this.state;
        const wisher = UserStore.childViewActive ? UserStore.activeChild.id : this.props.UserStore.user._id;
        let self = this;
        let timeWishedFor = Date.now();
        let priceToUse = price;
        // let family = this.props.FamilyStore.activeGroupId;
        let groups = this.state.checkedListIds;

        if (price === null) {
            priceToUse = 0;
        }
        if (this.state.title === "") {
            message.error("Cannot leave title blank");
        }
        else if (this.state.checkedList.length === 0) {
            message.error("Must select at least one group");
        } else {
            this.setState({ submittingNewGift: true });
            createGift(auth,
                title,
                description,
                category,
                priceToUse,
                link,
                wisher,
                timeWishedFor,
                groups).then((response) => {
                    self.setState({
                        title: "",
                        description: "",
                        link: "",
                        imageLink: "",
                        price: 0,
                        modalOpen: false,
                        submittingNewGift: false
                    });
                    MyListStore.loadGiftsForUser(UserStore.user._id, UserStore.user._id, FamilyStore.activeGroupId);
                });
        }
    }

    onChange(checkedList) {
        const { UserStore } = this.props;       
        let idArray = [];
        UserStore.user.groups.forEach((group) => {
            if (checkedList.includes(group.name)) {
                idArray.push(group.id);
            }
        });
        this.setState({
            checkedList,
            checkedListIds: idArray,
            indeterminate: !!checkedList.length && checkedList.length < this.state.plainOptions.length,
            checkAll: checkedList.length === this.state.plainOptions.length,
        });
    }

    onCheckAllChange(e) {
        const { UserStore } = this.props;
        this.setState({
            checkedList: e.target.checked ? this.state.plainOptions : [],
            checkedListIds: e.target.checked ? UserStore.user.groups.map(group => group.id) : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    }   

    handleClick = e => {
        console.log('click ', e);
        this.setState({
            current: e.key,
        });
        switch (e.key) {
            case ("item_0"):
                this.setState({ category: "books", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Books</span> <Icon type="book" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_1"):
                this.setState({ category: "camping", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Camping</span> <FaCampground style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_2"):
                this.setState({ category: "clothing", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Clothing</span> <Icon type="skin" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_3"):
                this.setState({ category: "tools", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Tools</span> <Icon type="tool" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_4"):
                this.setState({ category: "art", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Art</span> <Icon type="picture" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_5"):
                this.setState({ category: "food", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Food</span> <FaUtensils style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_6"):
                this.setState({ category: "appliances", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Appliances</span> <FaBlender style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_7"):
                this.setState({ category: "cosmetics", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Cosmetics</span> <FaBath style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_8"):
                this.setState({ category: "technology", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Technology</span> <FaTv style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_9"):
                this.setState({ category: "household", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Household</span> <FaHome style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_10"):
                this.setState({ category: "board games", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Board Games</span> <FaChess style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_11"):
                this.setState({ category: "video games", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Video Games</span> <FaGamepad style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_12"):
                this.setState({ category: "jewelry", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Jewelry</span> <FaRegGem style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_13"):
                this.setState({ category: "gift cards", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Gift Cards</span> <Icon type="credit-card" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_14"):
                this.setState({ category: "tickets", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Tickets</span> <FaTicketAlt style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_15"):
                this.setState({ category: "d&d", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Dungeons and Dragons</span> <FaDAndD style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_16"):
                this.setState({ category: "toys", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Toys</span> <FaTableTennis style={{ fontSize: "35pt" }} /></span> });
                break;

            default: this.setState({ dropdownDisplay: <span><Icon type="gift" style={{ fontSize: "35pt" }} /> <Icon type="down" /></span> });
        }
    }

    render() {
        const imageMenu = (
            <Menu onClick={this.handleClick}>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Books</span> <Icon type="book" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Camping</span> <FaCampground style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Clothing</span> <Icon type="skin" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Tools</span> <Icon type="tool" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Art</span> <Icon type="picture" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Food</span> <FaUtensils style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Appliances</span> <FaBlender style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Cosmetics</span> <FaBath style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Technology</span> <FaTv style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Household</span> <FaHome style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Board Games</span> <FaChess style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Video Games</span> <FaGamepad style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Jewelry</span> <FaRegGem style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Gift Cards</span> <Icon type="credit-card" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Tickets</span> <FaTicketAlt style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Dungeons and Dragons</span> <FaDAndD style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Toys</span> <FaTableTennis style={{ fontSize: "35pt" }} />
                </Menu.Item>
            </Menu>
        );
        return (
            <div >
                <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                    isOpen={this.state.modalOpen}
                    onRequestClose={() => { (this.state.submittingNewGift ? console.log("tried to close") : this.setState({ modalOpen: false })) }}>
                    {(!this.state.submittingNewGift ?
                        <div style={{ marginLeft: "4px", textAlign: "center" }}>
                            <span style={{ fontSize: "16pt" }} className="AppFont">Title</span>
                            <br />
                            <Input maxLength={30} value={this.state.title} onChange={(e) => { this.setState({ title: e.target.value }) }} placeholder="Brand Name Widget" />
                            <br />
                            <span style={{ fontSize: "16pt" }} className="AppFont">Description</span>
                            <br />
                            <TextArea onChange={(e) => { this.setState({ description: e.target.value }) }} placeholder="This is a..." autosize />
                            <br />
                            <span style={{ fontSize: "16pt" }} className="AppFont">Price</span>
                            <br />
                            <InputNumber
                                defaultValue={0.00}
                                min={0}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => { this.setState({ price: value }) }}
                            />
                            <br />
                            <span style={{ fontSize: "16pt" }} className="AppFont">Link</span>
                            <br />
                            <Input onChange={(e) => { this.setState({ link: e.target.value }) }} placeholder="https://......." />
                            <br />
                            <span style={{ fontSize: "16pt" }} className="AppFont">Select Gift Category</span>
                            <br />
                            <Dropdown overlay={imageMenu}>
                                {this.state.dropdownDisplay}
                            </Dropdown>

                            {this.state.showCheckboxes ?
                                <div className="AppFont" style={{ color: "white" }}>
                                    <br />
                                    <span style={{ fontSize: "16pt" }} >Select which groups can see this gift</span>
                                    <br />
                                    <Checkbox
                                        indeterminate={this.state.indeterminate}
                                        onChange={this.onCheckAllChange.bind(this)}
                                        checked={this.state.checkAll}
                                        style={{ color: "white" }}
                                    >
                                        Check all
          </Checkbox>
                                    <CheckboxGroup
                                        style={{ color: "white" }}
                                        options={this.state.plainOptions}
                                        value={this.state.checkedList}
                                        onChange={this.onChange.bind(this)}
                                    />
                                </div> :
                                ""}
                            {/* <Input onChange={(e) => { this.setState({ imageLink: e.target.value }) }} placeholder="https://......." /> */}
                            <br />
                            <br />
                            <Button className="AppFont btn-submit" style={{ color: "white", backgroundColor: "#F6D55C" }} onClick={this.onSubmitClick.bind(this)} size="large"><Icon type="check-circle" /> Submit</Button>
                            <Button className="AppFont btn-cancel" style={{ color: "white", backgroundColor: "#ED553B" }} onClick={() => { this.setState({ modalOpen: false }) }} size="large"><Icon type="close" /> Cancel</Button>
                        </div>

                        : <Icon type="loading" style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: 'translate("-50%", "-50%")', fontSize: "50pt"
                        }} />)}
                </Modal>
                <div >
                    <div style={this.props.divStyle} onClick={() => { this.props.FamilyStore.activeGroupId === "" ? message.warn("You must be in a group to add gifts!") : this.setState({ modalOpen: true }) }} size="large">
                        <Icon style={{fontSize: "14pt", paddingRight: "4px"}} type="gift" />Add Gift</div>
                </div>
            </div>
        )
    }
}
export default AddGift;
