import axios from "axios";
import { message } from "antd";

const url = process.env.REACT_APP_BACKEND_URL;
export async function createGift(
    auth,
    title,
    description,
    category,
    price,
    link,
    wisher,
    timeWishedFor,
    groups
) {
    return axios.post(url + "/gifts", {
        title,
        description,
        category,
        price,
        link,
        wisher,
        timeWishedFor,
        groups
    }, {
        headers: {
            Authorization: `Bearer ${auth}`
        }
    })
        .then(function (response) {
            message.success("Success, gift added!");
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            message.error("Error adding gift.");
            console.log(error);
        });
}

export async function getGifts(family) {
    return axios({
        method: 'get',
        url: url + "/gifts/" + family,
    }).then(res => {
        return res.data;
    });
}

export async function getGiftsByGroup(group) {
    return axios({
        method: 'get',
        url: url + "/gifts/groups/" + group,
    }).then(res => {
        return res.data;
    });
}

export async function getGiftsByUser(user) {
    return axios({
        method: 'get',
        url: url + "/gifts/users/" + user,
    }).then(res => {
        return res.data;
    });
}

export async function getImage(siteUrl, site) {
    return axios({
        method: 'put',
        url: url + "/image",
        data: { url: siteUrl, site }
    }).then(res => {
        return res.data;
    });
}

export async function editGift(giftId, title, description, link, category, price) {
    return axios({
        method: 'put',
        url: url + "/gifts/" + giftId,
        data: {
            title: title,
            description: description,
            category: category,
            link: link,
            price: price
        },
        // headers: { Authorization: `Bearer ${auth}` },
    }).then(res => {
        return res.data;
    });
}

export async function buyGift(giftId, purchaser, auth) {
    let time = Date.now();
    return axios({
        method: 'put',
        url: url + "/gifts/buy/" + giftId,
        data: {
            purchaser: purchaser,
            timePurchased: time
        },
        headers: {
            Authorization: `Bearer ${auth}`
        }
    })
        .then(function (response) {
            message.success("Success, gift bought!");
            console.log(response);
            return response.data;
        })
        .catch(function (error) {
            message.error("Error buying gift.");
            console.log(error);
        });
}


export async function addImage(giftId, link) {
    return axios({
        method: 'put',
        url: url + "/gifts/image/" + giftId,
        data: {
            link
        }
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export async function returnGift(giftId) {
    return axios({
        method: 'put',
        url: url + "/gifts/return/" + giftId
    }).then(function (response) {
        message.success("Success, gift returned!");
        console.log(response);
    })
        .catch(function (error) {
            message.error("Error returning gift.");
            console.log(error);
        });
}

export async function deleteGift(giftId) {
    return axios({
        method: 'delete',
        url: url + "/gifts/" + giftId
    }).then(res => {
        return res.data;
    });
}