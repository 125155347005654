import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import { Icon, Button, Input, message, List } from 'antd';
import { sendResetEmail } from "../api_calls/user";


const headerFontSize = "14pt";

Modal.setAppElement('#root')
@observer(["UserStore"])
class ForgotPasswordButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            hashPass: "",
            family: "",
            forgotModalIsOpen: false,
        };
        this.closeForgotModal = this.closeForgotModal.bind(this);
        this.submitForgot = this.submitForgot.bind(this);
    }

    submitForgot() {
        this.setState({ forgotModalIsOpen: false });
        sendResetEmail(this.state.forgotEmail).then((res) => {
            console.info(res);
        })

    }

    closeForgotModal() {
        this.setState({ forgotModalIsOpen: false });
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.forgotModalIsOpen}
                    onRequestClose={this.closeForgotModal}
                    contentLabel="Forgot Modal"
                    className="register-modal"
                    overlayClassName="register-modal-overlay">
                    <div className="AppFont" style={{ fontSize: headerFontSize, color: "white", textAlign: "center" }}>
                        Email <Input id="password-forgot" style={{ textAlign: "center" }} type="email" onChange={(e) => { this.setState({ forgotEmail: e.target.value }) }} />
                        <br />
                        <br />
                        <br />
                        <Button className="btn-submit" id="submitBtn-forgot" onClick={this.submitForgot} size="large" >Send Reset Link To My Email</Button>
                    </div>
                </Modal>
                <a
                    onClick={(e) => { this.setState({ forgotModalIsOpen: true }) }}
                    style={{ fontSize: "7pt", padding: "5px", borderRadius: "40px", backgroundColor: "rgba(0,0,0,0.3)", color: "white", fontWeight: "bold" }}>Forgot password?</a>
            </div>
        );
    }
}

export default ForgotPasswordButton;