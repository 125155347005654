import Gift from '../models/GiftModel';
import Receipt from '../models/ReceiptModel';
import { types } from "mobx-state-tree";
import { getGifts, getGiftsByGroup, getGiftsByUser } from '../api_calls/gift';
import { getReceipts } from '../api_calls/receipt';
import _ from 'underscore';
const MyListModel = types
    .model('MyList', {
        loading: types.boolean,
        loaded: types.boolean,
        gifts: types.optional(types.array(Gift), []),
        receipts: types.optional(types.array(Receipt), []),
        myList: types.optional(types.array(Gift), []),
        currentRanking: types.optional(types.array(types.string), [])
    })
    .views(self => {
        return {
            isLoaded: () => self.loaded,
            isLoading: () => self.loading,
            getReceipt: rctId => _.findWhere(self.receipts, {_id: rctId}),
            getGift: giftId => _.findWhere(self.gifts, {_id: giftId})
        }
    })
    .actions(self => {
        return {
            setLoading: (loading) => {
                self.loading = loading;
            },
            setLoaded: (loaded) => {
                self.loaded = loaded;
            },
            determineRanking: (user, groupId) => {
                let ranks = user.listRank.get(groupId) || [];
                self.setCurrentRanking(ranks);
            },
            setCurrentRanking: (ranking) => {
                let ranks = [];
                ranking.forEach((rank) => {
                    ranks.push(rank);
                })
                self.currentRanking = ranks;
            },
            loadGiftsForUser: async (user, me, activeGroupId) => {
                self.setLoading(true);
                self.setLoaded(false);
                getGiftsByUser(user).then((gifts) => {
                    self.setGifts(gifts.filter(g => g.groups.includes(activeGroupId)), me);
                });
            },
            loadGifts: async (family, me) => {
                self.setLoading(true);
                self.setLoaded(false);
                getGifts(family).then((gifts) => {
                    let giftsToUse = [];
                    if (gifts && gifts.length) {
                        giftsToUse = gifts;
                    }
                    getGiftsByGroup(family).then((groupGifts) => {
                        let groupGiftsToUse = [];
                        if (groupGifts && groupGifts.length) {
                            groupGiftsToUse = groupGifts;
                        }
                        let allGifts = giftsToUse.concat(groupGiftsToUse);
                        let uniqGifts = [];
                        let uniqGiftIds = [];
                        allGifts.forEach((gift) => {
                            if  (!uniqGiftIds.includes(gift._id)) {
                                uniqGiftIds.push(gift._id);
                                uniqGifts.push(gift);
                            }
                        });
                        self.setGifts(uniqGifts, me);
                    });
                });
            },
            setGifts: (gifts, me) => {
                if (gifts !== null) {
                    self.gifts = gifts;
                    self.setMyList(gifts, me);
                }
            },
            loadReceipts: (auth) => {
                getReceipts(auth).then((res) => {
                    self.setReceipts(res);
                });
            },
            setReceipts: (rcts) => {
                self.receipts = rcts;
            }, 
            setMyList: (gifts, me) => {
                let myListArray = [];
                gifts.forEach(gift => {
                    if (gift.wisher === me) {
                        myListArray.push(gift);
                    }
                });
                self.myList = myListArray;
                self.setLoading(false);
                self.setLoaded(true);
            }
        }
    });

const MyListStore = MyListModel.create({
    loading: false,
    loaded: false,
    currentRanking: []
});
export default MyListStore;