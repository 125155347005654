import React, { Component } from 'react';
import { observer } from "mobx-react";
import Modal from 'react-modal';
import { sha512 } from 'hash-anything';
import { Radio, Button, Tooltip, Input, Menu, Icon, Layout, Drawer, Select, Checkbox, Affix, message, InputNumber } from "antd";
import { FaSignOutAlt, FaReceipt, FaCog, FaClipboard, FaBars, FaTimes, FaEllipsisV } from "react-icons/fa";
import NavViews from '../stores/NavViews';
import ChildAccounts from './ChildAccounts';
import GroupSettings from './GroupSettings';
import { updateProfileImageLocation, changePassword, addKidToGroup, updateUser } from "../api_calls/user";
import { slide as MobileMenu } from 'react-burger-menu';
import ImageUploader from 'react-images-upload';
import AWS from "aws-sdk";
import _ from "underscore";
import { updateGroup } from '../api_calls/group';
import { menu as menuStyles, settings as settingStyles } from '../utils/styles/menuStyles';
import { allThemes } from "../utils/styles/colours";
import { createLog, sessionEnd } from '../api_calls/logging';
const allowedImageTypes = ["image/jpeg"];
const maxImageSize = 5000000;
const mobileWidth = 580;

var albumBucketName = "listgifts-pics";
var bucketRegion = "us-east-2";
var IdentityPoolId = "us-east-2:e467c664-233b-4e2b-ac1f-36d6fb672768";

AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
    })
});

var s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    params: { Bucket: albumBucketName, ContentType: 'image/jpeg' },
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY
});

const { Header, Sider } = Layout;
const { Option } = Select;
@observer(["UserStore", "ViewStore", "CustomRouter", "AuthStore", "FamilyStore", "MyListStore"])
class MyHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            selectedKey: "1",
            pictures: [],
            settingsVisible: false,
            drops: 0,
            childOptionsVisible: false,
            uploadingImage: false,
            modalOpen: false
        };
        this.onDrop = this.onDrop.bind(this);
        this.onSettingsClicked = this.onSettingsClicked.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChildOptionsChange = this.onChildOptionsChange.bind(this);

        this.addPhoto = this.addPhoto.bind(this);
        this.deletePhoto = this.deletePhoto.bind(this);
        this.onSubmitPassChangeClick = this.onSubmitPassChangeClick.bind(this);

        this.addChildToGroup = this.addChildToGroup.bind(this);
        this.dontAddChildToGroup = this.dontAddChildToGroup.bind(this);

        this.activeGroup = this.activeGroup.bind(this);
        this.giftRankingPermissionChange = this.giftRankingPermissionChange.bind(this);
        this.giftRankingMaxChange = this.giftRankingMaxChange.bind(this);
        this.onThemeChange = this.onThemeChange.bind(this);
    }

    deletePhoto(albumName, photoKey) {
        s3.deleteObject({ Key: photoKey }, function (err, data) {
            if (err) {
                return alert("There was an error deleting your photo: ", err.message);
            }
            alert("Successfully deleted photo.");
            this.viewAlbum(albumName);
        });
    }

    componentDidUpdate() {
        const { UserStore, FamilyStore } = this.props;
        let stateComplete = this.state.updateComplete;
        document.body.style.backgroundColor = UserStore.theme().appBackground;

        if (document.querySelector(".ant-menu-dark") && document.querySelector(".ant-menu-dark").style) {
            document.querySelector(".ant-menu-dark").style.backgroundColor = UserStore.theme().header;
        }

        if (document.querySelector(".ant-drawer-body")) {
            document.querySelector(".ant-drawer-body").style.backgroundColor = this.props.UserStore.theme().background;
        }

        if (document.querySelector(".ant-drawer-content")) {
            document.querySelector(".ant-drawer-content").style.backgroundColor = this.props.UserStore.theme().background;
        }

        if (UserStore.children && UserStore.children.length && UserStore.childViewActive && !this.state.wrongGroupModalOpen) {
            let thisChild = _.findWhere(UserStore.children, { _id: UserStore.activeChild.id });
            if (thisChild && (thisChild._id.concat(FamilyStore.activeGroupId) !== stateComplete) && !_.findWhere(thisChild.groups, { id: FamilyStore.activeGroupId })) {
                this.setState({
                    wrongGroupModalOpen: true
                });
            }
        }
    }

    addPhoto(file, albumName) {
        const { UserStore, AuthStore } = this.props;
        let userId = UserStore.user._id;
        var fileName = file.name;
        var albumPhotosKey = encodeURIComponent(albumName) + "/";
        var photoKey = albumPhotosKey.concat(userId).concat(".jpg");
        let self = this;

        var upload = s3.upload({
            Key: photoKey,
            Body: file
        });

        var promise = upload.promise();

        promise.then(
            function (data) {
                self.setState({ uploadingImage: false });
                message.info("Successfully uploaded photo!");
                UserStore.setProfileImage(data.Location);

                updateProfileImageLocation(data.Location, UserStore.user._id, AuthStore.userAuth.auth.accessToken).then(() => {
                    self.setState({ pictures: [] });
                    var imgtag = document.getElementsByClassName("profile-img-settings");
                    setTimeout(() => {
                        for (let item of imgtag) {
                            item.src = data.Location;
                        }
                    }, 50);
                    return UserStore.refreshUser(AuthStore.userAuth.auth.accessToken);
                })
            },
            function (err) {
                self.setState({ uploadingImage: false });
                message.error("There was an error uploading your photo...");
                console.error(err.message);
            }
        );
    }

    componentWillMount() {
        if (this.props.CustomRouter.router.currentPath === "" || this.props.CustomRouter.router.currentPath === "/") {
            this.setState({ selectedKey: "1" });
        }
        if (this.props.CustomRouter.router.currentPath === "/groups") {
            this.setState({ selectedKey: "2" });
        }
        if (this.props.CustomRouter.router.currentPath === "/events") {
            this.setState({ selectedKey: "3" });
        }
        if (this.props.CustomRouter.router.currentPath === "/my-notes") {
            this.setState({ selectedKey: "4" });
        }
        if (this.props.CustomRouter.router.currentPath === "/receipts") {
            this.setState({ selectedKey: "5" });
        }
    }

    componentDidMount() {
        this.setState({ childOptionsVisible: this.props.UserStore.user.childOptionsVisible, activeGroup: this.props.FamilyStore.activeGroupId });
        const self = this;
        document.addEventListener('visibilitychange', function logData() {
            
            if (document.visibilityState === 'hidden') {
                const endObj = {
                    userId: self.props.UserStore.user._id,
                    type: "sessionEnd"
                };
                createLog(self.props.AuthStore.userAuth.auth.accessToken, endObj);
            }
            if (document.visibilityState === 'visible') {
                const startObj = {
                    userId: self.props.UserStore.user._id,
                    type: "sessionStart",
                    log: "fromHidden"
                };
                createLog(self.props.AuthStore.userAuth.auth.accessToken, startObj);
            }
        });
    }

    onSettingsClicked() {
        this.setState({ settingsVisible: true, childOptionsVisible: this.props.UserStore.user.childOptionsVisible });
        const imageFile = this.props.UserStore.profileImage;
        var imgtag = document.getElementsByClassName("profile-img-settings");
        setTimeout(() => {
            if (document.querySelector(".ant-drawer-body")) {
                document.querySelector(".ant-drawer-body").style.backgroundColor = this.props.UserStore.theme().background;
            }
            if (document.querySelector(".ant-drawer-content")) {
                document.querySelector(".ant-drawer-content").style.backgroundColor = this.props.UserStore.theme().background;
            }
            for (let item of imgtag) {
                item.src = imageFile;
            }
        }, 50);
    }

    onClose() {
        this.setState({ settingsVisible: false });
    }

    onDrop(picture) {
        const { UserStore, AuthStore } = this.props;
        this.setState({
            drops: this.state.drops + 1
        });
        var selectedFile = this.state.drops - 1 > 0 ? this.state.pictures.concat(picture)[0] : this.state.pictures.concat(picture)[this.state.pictures.concat(picture).length - 1];

        let self = this;

        if (selectedFile && selectedFile.type && allowedImageTypes.includes(selectedFile.type)) {
            var reader = new FileReader();
            var imgtag = document.getElementsByClassName("profile-img")[0];
            if (imgtag) {
                imgtag.title = selectedFile.name;
            }
            if (selectedFile.size && (selectedFile.size < maxImageSize)) {
                this.setState({ uploadingImage: true });
                self.addPhoto(selectedFile, "profile-images");
            } else {
                message.warn("That image is too big!");
            }
        } else if (selectedFile && selectedFile.type && !allowedImageTypes.includes(selectedFile.type)) {
            message.warn("That file type is not supported! Try a '.jpg'");
        } else {
            message.warn("Sorry, that didn't work. Maybe the file is too big or the wrong type");
        }
    }

    onChange(value) {
        const { UserStore, FamilyStore } = this.props;
        this.setState({ activeGroup: value });
        this.props.FamilyStore.setActiveGroup(value);
        const realUser = UserStore.childViewActive ? UserStore.activeChild.id : UserStore.user._id;
        this.props.MyListStore.loadGiftsForUser(realUser, realUser, FamilyStore.activeGroupId);
    }

    activeGroup() {
        return this.props.FamilyStore.activeGroupId;
    }

    onChildOptionsChange(e) {
        this.props.UserStore.setChildOptionsVisible(e.target.checked);
    }

    onSubmitPassChangeClick() {
        const { UserStore, AuthStore } = this.props;
        const auth = AuthStore.userAuth.auth.accessToken;
        const passHash = this.state.hashPass;
        const newPassHash = this.state.newHashPass;
        const newPassHashConf = this.state.newHashPassConfirm;

        if (!newPassHash || !newPassHashConf || !passHash) {
            message.warn("Please complete all fields");
        }
        else if (newPassHashConf !== newPassHash) {
            message.warn("New password and confirmation do not match!");
        }
        else {
            changePassword(UserStore.user._id, passHash, newPassHash, auth).then((res) => {
                this.setState({ modalOpen: false });
            });
        }
    }

    addChildToGroup() {
        const { AuthStore, UserStore } = this.props;
        const auth = AuthStore.userAuth.auth.accessToken;
        const groupId = this.props.FamilyStore.activeGroupId;
        const groupName = this.props.FamilyStore.activeGroupName;
        const userId = this.props.UserStore.activeChild.id;
        let self = this;
        addKidToGroup(auth, groupId, groupName, userId).then((res) => {
            if (res && (res._id === groupId)) {
                message.success("Added child to group!");
                UserStore.refreshUser(AuthStore.userAuth.auth.accessToken, groupId).then(() => {
                    self.setState({
                        wrongGroupModalOpen: false,
                        updateComplete: userId.concat(groupId)
                    });
                });
            }
            else {
                self.setState({ wrongGroupModalOpen: false });
            }
        });
    }

    dontAddChildToGroup() {
        const { FamilyStore, UserStore } = this.props;
        const thisKid = _.findWhere(UserStore.children, { _id: UserStore.activeChild.id });
        FamilyStore.setActiveGroup(thisKid.groups[0].id);
        UserStore.refreshGroups(thisKid.groups[0].id);
        this.setState({ wrongGroupModalOpen: false, activeGroup: thisKid.groups[0].id });

    }

    giftRankingPermissionChange(e) {
        const { FamilyStore, AuthStore, UserStore } = this.props;
        updateGroup(FamilyStore.activeGroupId, this.props.AuthStore.userAuth.auth.accessToken, { $set: { 'settings.allowRankings': e.target.checked } }).then((res, err) => {
            if (res && res.status === 200) {
                UserStore.refreshUser(AuthStore.userAuth.auth.accessToken, FamilyStore.activeGroupId);
                message.success("Settings changed!");
            }
            else {
                message.error(err);
            }
        });
    }

    giftRankingMaxChange(value) {
        const { FamilyStore, AuthStore, UserStore } = this.props;
        updateGroup(FamilyStore.activeGroupId, this.props.AuthStore.userAuth.auth.accessToken, { $set: { 'settings.rankingMax': value } }).then((res, err) => {
            if (res && res.status === 200) {
                UserStore.refreshUser(AuthStore.userAuth.auth.accessToken, FamilyStore.activeGroupId);
                message.success("Settings changed!");
            }
            else {
                message.error(err);
            }
        });
    }

    showSettings(event) {
        event.preventDefault();
    }

    onThemeChange(e) {
        const { UserStore, AuthStore, FamilyStore } = this.props;
        console.log('radio checked', e.target.value);
        const id = UserStore.user._id;
        const token = AuthStore.userAuth.auth.accessToken;
        const mongo = { $set: { 'settings.theme': _.keys(allThemes)[e.target.value] } }
        updateUser(id, token, mongo).then((res) => {
            if (res && res.status && res.status == 200) {
                message.success("Theme updated!");
                UserStore.refreshUser(token, FamilyStore.activeGroupId);
            }
            else {
                message.error("Problem updating theme...");
            }
        });
    }

    render() {
        const { CustomRouter, UserStore, FamilyStore } = this.props;
        if (document.querySelector(".ant-menu-item-selected")) {
            document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().icons;
        }

        const mobileMenuItemStyles = {
            color: UserStore.theme().font
        };
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
        const settings = <div>
            <div style={{ color: "#0c273b", fontSize: "26pt" }} className="AppFont">
                <img alt="" className="profile-img-settings" style={{ width: "50px", height: "50px", borderRadius: "30px" }} />
                {this.props.UserStore.user.name} <br />
            </div>
            {this.state.uploadingImage ?
                <div style={{ textAlign: "center" }}>
                    Uploading Image...
                    <Icon style={{ fontSize: "30pt" }} type="loading" />
                </div>
                : ""}

            <div style={{ filter: this.state.uploadingImage ? "blur(5px)" : "", pointerEvents: this.state.uploadingImage ? "none" : "" }}>
                <ImageUploader
                    buttonStyles={{ backgroundColor: UserStore.theme().icons }}
                    fileContainerStyle={{ backgroundColor: UserStore.theme().background }}
                    label={`Max file size is ${maxImageSize / 1000 / 1000}MB`}
                    withIcon={false}
                    buttonText='Change Profile Image'
                    onChange={this.onDrop}
                    imgExtension={['.jpg', '.gif', '.png']}
                    maxFileSize={10000000}
                />
            </div>

            {this.props.UserStore.user.groups.length < 2 ? null :
                <div style={{ position: "relative" }} id="activeGroupContainer">
                    {/* Active Group */}
                    <Select
                        getPopupContainer={() => document.getElementById("activeGroupContainer")}
                        id="activeGroupSelect"
                        style={{ width: 200 }}
                        placeholder="Active Group"
                        optionFilterProp="children"
                        onChange={this.onChange}
                        value={this.state.activeGroup}
                        defaultValue={this.state.activeGroup}
                        filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {this.props.UserStore.user.groups.map((group) => {
                            return <Option value={group.id}>{group.name}</Option>
                        })}
                    </Select>
                    <br />
                </div>
            }
            <br />
            {FamilyStore.activeGroup.creator == UserStore.user._id ?
                <div>
                    <span style={{ fontWeight: "bold" }}>Group Settings</span>
                    <hr />
                    <div>Gift Ranking<Checkbox style={{ marginLeft: "7px" }} checked={FamilyStore.activeGroup.settings.allowRankings} defaultChecked={FamilyStore.activeGroup.settings.allowRankings} onChange={this.giftRankingPermissionChange} /> </div>
                    <div>Ranking Max<InputNumber style={{ marginLeft: "7px" }} value={FamilyStore.activeGroup.settings.rankingMax} defaultValue={FamilyStore.activeGroup.settings.rankingMax} onChange={this.giftRankingMaxChange} /> </div>
                    <GroupSettings />
                </div> : null}
            <br />

            <div>
                <span style={{ fontWeight: "bold" }}>Child Settings</span>
                <hr />
                Show Options<Checkbox defaultChecked={this.props.UserStore.user.childOptionsVisible} onChange={this.onChildOptionsChange} />
                {UserStore.user.childOptionsVisible ? <ChildAccounts /> : null}
            </div>
            <br />
            <div>
                <span style={{ fontWeight: "bold" }}>Display Settings</span>
                <hr />
                <u>Themes</u>
                <br />

                <Radio.Group onChange={this.onThemeChange} defaultValue={_.keys(allThemes).indexOf(UserStore.user.settings.theme)} >
                    {_.keys(allThemes).map((themeId, i) => {
                        return <div style={{ float: 'left' }} >
                            <Radio style={radioStyle} value={i}>{allThemes[themeId].title}</Radio>
                            <div style={{ width: '100px', height: '20px', display: 'flex' }}>
                                <span style={{ width: '20px', height: '20px', backgroundColor: allThemes[themeId].appBackground }}></span>
                                <span style={{ width: '20px', height: '20px', backgroundColor: allThemes[themeId].header }}></span>
                                <span style={{ width: '20px', height: '20px', backgroundColor: allThemes[themeId].icons }}></span>
                            </div>
                        </div>
                    }
                    )}
                </Radio.Group>
            </div>
            <hr />
            <div>
                <Button onClick={() => this.setState({ modalOpen: true })}>Change Password</Button>
            </div>
            <div>
                <Button style={{ margin: "2px" }} onClick={this.props.onLoggedOut} >
                    Log Out &nbsp;<Tooltip title="log out"><FaSignOutAlt /></Tooltip>
                </Button>
            </div>
            <br />
            <div>
                Need help?
                <br />
                <a href="mailto:help@list.gifts">Send us an email</a>
            </div>
        </div>;

        return (<div>

            <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                isOpen={this.state.modalOpen}
            >
                <div>
                    <span style={{ fontSize: "16pt", color: "#3CAEA3" }} className="AppFont">Current Password</span>
                    <br />
                    <Input className="password-spoof ant-input" onChange={(e) => { this.setState({ hashPass: sha512(e.target.value) }) }} />
                    <br />
                    <span style={{ fontSize: "16pt", color: "#3CAEA3" }} className="AppFont">New Password</span>
                    <br />
                    <Input className="password-spoof ant-input" onChange={(e) => { this.setState({ newHashPass: sha512(e.target.value) }) }} />
                    <br />
                    <span style={{ fontSize: "16pt", color: "#3CAEA3" }} className="AppFont">Confirm New Password</span>
                    <br />
                    <Input className="password-spoof ant-input" onChange={(e) => { this.setState({ newHashPassConfirm: sha512(e.target.value) }) }} />
                    <br />
                    <br />

                    <Button className="AppFont" style={{ color: "white", backgroundColor: "#F6D55C" }} onClick={this.onSubmitPassChangeClick.bind(this)} size="large"><Icon type="check-circle" /> Submit</Button>
                    <Button className="AppFont" style={{ color: "white", backgroundColor: "#ED553B" }} onClick={() => { this.setState({ modalOpen: false }) }} size="large"><Icon type="close" /> Cancel</Button>
                </div>
            </Modal>
            <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                isOpen={this.state.wrongGroupModalOpen}>

                <div style={{ marginLeft: "4px", textAlign: "center" }}>
                    <h2 className="AppFont" style={{ color: "white", marginTop: "10px" }}>Looks like you haven't added the active child to the active group...</h2>
                    <h1 className="AppFont" style={{ color: "white", marginTop: "10px" }}>Would you like to add {this.props.UserStore.activeChild ? this.props.UserStore.activeChild.name : "this child"} to the group "{this.props.FamilyStore.activeGroupName}"?</h1>
                    <Button onClick={this.addChildToGroup.bind(this)}>Yes</Button>
                    <Button onClick={this.dontAddChildToGroup.bind(this)}>No</Button>
                </div>

            </Modal>
            {vw < mobileWidth ?
                <Affix offset={0} >
                    {UserStore.childViewActive ?
                        <div style={{ position: "fixed", backgroundColor: "rgba(252, 142, 134, 0.85)", width: "100%", top: "0px", height: "20px", left: 0, zIndex: 1002 }}>
                            <span style={{ color: "white", position: "relative" }}>Viewing as: {UserStore.activeChild.name}</span>
                        </div> : null
                    }
                    <div id="mobileMenus" style={{ zIndex: 1001, backgroundColor: UserStore.theme().header || "white", width: "100%", height: "36px" }}>
                        <MobileMenu disableAutoFocus
                            onStateChange={this.onSettingsClicked}
                            customBurgerIcon={<FaEllipsisV />}
                            styles={
                                settingStyles({
                                    bmItem: { backgroundColour: UserStore.theme().background },
                                    bmBurgerBars: { backgroundColour: UserStore.theme().icons }
                                })}
                            right>
                            <div style={{ color: UserStore.theme().font }}>
                                {settings}
                            </div>
                        </MobileMenu>

                        <MobileMenu
                            customBurgerIcon={<FaBars />}
                            onClose={() => this.setState({ mobileMenuOpen: false })}
                            onOpen={() => this.setState({ mobileMenuOpen: true })}
                            isOpen={this.state.mobileMenuOpen}
                            disableAutoFocus
                            styles={
                                menuStyles({
                                    bmItem: { backgroundColour: UserStore.theme().background },
                                    bmBurgerBars: { backgroundColour: UserStore.theme().icons }
                                })}
                            left>
                            <div
                                style={mobileMenuItemStyles}
                                onClick={() => {
                                    this.setState({ mobileMenuOpen: false });
                                    CustomRouter.router.goTo(NavViews.home);
                                }}
                                key="1">
                                <Icon type="bars" />
                                <span>List</span>
                            </div>
                            <div style={mobileMenuItemStyles} className="menu-item" onClick={() => {
                                this.setState({ mobileMenuOpen: false });
                                CustomRouter.router.goTo(NavViews.groups);
                            }} key="2">
                                <Icon type="team" />
                                <span>Group</span>
                            </div>
                            <div style={mobileMenuItemStyles} className="menu-item" onClick={() => {
                                this.setState({ mobileMenuOpen: false });
                                CustomRouter.router.goTo(NavViews.events);
                            }} key="3">
                                <Icon type="calendar" />
                                <span>Events</span>
                            </div>
                            <div style={mobileMenuItemStyles} className="menu-item" onClick={() => {
                                this.setState({ mobileMenuOpen: false });
                                CustomRouter.router.goTo(NavViews.myNotes);
                            }} key="4">
                                <FaClipboard style={{ marginRight: '5px' }} />
                                <span>Notes</span>
                            </div>
                            <div style={mobileMenuItemStyles} className="menu-item" onClick={() => {
                                this.setState({ mobileMenuOpen: false });
                                CustomRouter.router.goTo(NavViews.receipts);
                            }} key="5">
                                <FaReceipt style={{ marginRight: '5px' }} />
                                <span>Records</span>
                            </div>
                        </MobileMenu>
                    </div>
                </Affix>
                :
                <Affix offsetTop={0}>
                    <Header style={{ backgroundColor: UserStore.theme().header, background: UserStore.theme().header }}>
                        <div className="logo" />
                        {/* <img alt="Logo" style={{ position: "absolute", top: "2px", left: "2px" }} width="45px" height="45px" src={require("../assets/gift-flat.png")} /> */}
                        {UserStore.childViewActive ?
                            <div style={{ position: "fixed", backgroundColor: "rgba(245, 96, 86, 0.85)", width: "100%", top: "0px", height: "14px", left: 0, zIndex: 2 }}>
                                <span style={{ color: "white", position: "relative", bottom: "25px" }}>Viewing as: {UserStore.activeChild.name}</span>
                            </div> : null
                        }

                        <div style={{ position: "absolute", top: "1px", right: "3%" }}>
                            <Button onClick={this.onSettingsClicked} style={{ margin: "2px" }} >
                                <FaCog />
                            </Button>
                        </div>

                        <Drawer
                            placement="right"
                            closable={false}
                            onClose={this.onClose}
                            visible={this.state.settingsVisible}>
                            <div style={{ color: UserStore.theme().font, backgroundColor: UserStore.theme().background }}>
                                {settings}
                            </div>
                        </Drawer>
                        {this.props.UserStore.role === "guest" ? null :
                            <Menu
                                defaultSelectedKeys={[this.state.selectedKey]}
                                defaultOpenKeys={[]}
                                mode="horizontal"
                                style={{ lineHeight: '64px' }}
                                theme="dark"
                                className="topBarMenu"
                            >
                                <Menu.Item onClick={() => {
                                    document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().header;
                                    setTimeout(() => {
                                        document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().icons;
                                    }, 100);
                                    CustomRouter.router.goTo(NavViews.home);
                                }} key="1">
                                    <Icon type="bars" />
                                    <span>List</span>
                                </Menu.Item>
                                <Menu.Item onClick={() => {
                                    document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().header;
                                    setTimeout(() => {
                                        document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().icons;
                                    }, 100);
                                    CustomRouter.router.goTo(NavViews.groups);
                                }} key="2">
                                    <Icon type="team" />
                                    <span>Group</span>
                                </Menu.Item>
                                <Menu.Item onClick={() => {
                                    document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().header;
                                    setTimeout(() => {
                                        document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().icons;
                                    }, 100);
                                    CustomRouter.router.goTo(NavViews.events);
                                }} key="3">
                                    <Icon type="calendar" />
                                    <span>Events</span>
                                </Menu.Item>
                                <Menu.Item onClick={() => {
                                    document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().header;
                                    setTimeout(() => {
                                        document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().icons;
                                    }, 100);
                                    CustomRouter.router.goTo(NavViews.myNotes);
                                }} key="4">
                                    <FaClipboard style={{ marginRight: '5px' }} />
                                    <span>Notes</span>
                                </Menu.Item>
                                <Menu.Item onClick={() => {
                                    document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().header;
                                    setTimeout(() => {
                                        document.querySelector(".ant-menu-item-selected").style.backgroundColor = UserStore.theme().icons;
                                    }, 100);
                                    CustomRouter.router.goTo(NavViews.receipts);
                                }} key="5">
                                    <FaReceipt style={{ marginRight: '5px' }} />
                                    <span>Records</span>
                                </Menu.Item>
                            </Menu>
                        }
                    </Header>
                </Affix>}
        </div>
        )
    }
}
export default MyHeader;