import React, { Component } from 'react'
import { List, Icon, Button, Popconfirm, notification } from "antd";
import { deleteNote } from '../api_calls/user';
import { observer } from 'mobx-react';

@observer(['UserStore', 'AuthStore'])
class Notes extends Component {
    constructor(props) {
        super(props);
        this.state = {deletingNotify: ""}
        this.confirmDelete = this.confirmDelete.bind(this);
    }
    confirmDelete(note) {
        const { UserStore, AuthStore } = this.props;
        notification.open({
            message: 'Deleting your note',          
            icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
            duration: 1
          });
      
        console.log("key" + note);
        let user = UserStore.childViewActive ? UserStore.activeChild.id : UserStore.user._id;
        deleteNote(user, note).then(() => {
            UserStore.refreshUser(AuthStore.userAuth.auth.accessToken);
        });
    }
    render() {
        const listItems = this.props.notes.map((note, key) =>
            <div className="NoteFont" style={{ fontSize: "18pt", color: "white" }}>
                - {note}
                <br />
                <Popconfirm title="Are you sure delete this note?" okText="Yes" onConfirm={this.confirmDelete.bind(this, note)} cancelText="No">
                    <Button className="AppFont" style={{color: "white", backgroundColor: "#ED553B"}}><Icon style={{ fontSize: "12pt" }} type="delete" /></Button>
                </Popconfirm>
            </div>);
        return (
            <div className="AppFont" style={{padding: '45px'}} >        
                <List
                    size="large"
                    header={<div className="NoteFont" style={{ fontSize: "20pt", color: "white" }}>Notes</div>}
                    style={{ background: "rgba(0, 0, 0, 0.35)" }}
                    bordered
                    dataSource={listItems}
                    renderItem={item => (<List.Item style={{ padding: "15px" }}>{item}</List.Item>)}
                />
            </div>
        )
    }
}
export default Notes;
