import React, { Component } from 'react';

class GroupSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (<div style={{paddingTop: "7px"}}>
            <u>Event Creators</u>
            <br />
            <div>You</div>
        </div>
        );
    }
}

export default GroupSettings;