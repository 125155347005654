import { FaCandyCane, FaGlassCheers, FaHanukiah } from "react-icons/fa";

import React, { Component } from 'react'
// import {FaCandyCane, FaHanukiah} from 'react-icons/fa'
export const icons = (iconStyle) => [
    {
        id: "xmas",
        name: "Christmas",
        component: <FaCandyCane style={iconStyle} />
    },
    {
        id: "hanukkah",
        name: "Hanukkah",
        component: <FaHanukiah style={iconStyle} />
    },
    {
        id: "celebration",
        name: "Celebration",
        component: <FaGlassCheers style={iconStyle} />
    }
];