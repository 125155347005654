import React, { Component } from 'react'
import { observer } from 'mobx-react';
import { Button, Icon, Input, message } from 'antd';
import Modal from 'react-modal';
import { createGroup } from '../api_calls/user';
Modal.setAppElement('#root')
@observer(["UserStore","AuthStore", "FamilyStore"])
class CreateGroup extends Component {

    constructor(props) {
        super(props);
        this.state = { modalOpen: false, name: "" }
        this.buttonClicked = this.buttonClicked.bind(this);
        this.submitGroupClicked = this.submitGroupClicked.bind(this);
    }

    submitGroupClicked() {
        const {AuthStore} = this.props;
        this.setState({ modalOpen: false });
        let self = this;
        createGroup(this.state.name, [this.props.UserStore.user._id], this.props.UserStore.user._id, AuthStore.userAuth.auth.accessToken).then(() => {
            self.props.UserStore.refreshGroups(this.props.UserStore.user._id, this.props.FamilyStore.activeGroupId);
        });
    }

    buttonClicked() {
        if (this.props.UserStore.childViewActive) {
            message.warn("Cannot create a group as a child account");
        } else {
        this.setState({ modalOpen: true });
        }
    }

    closeModal() {
        this.setState({modalOpen: false});
    }

    render() {
        const {UserStore} = this.props;
        return (
            <div>
                <Modal className="group-add-modal" overlayClassName="group-add-modal-overlay" style={{ width: "100%", height: "65%" }}
                    isOpen={this.state.modalOpen}
                    onRequestClose={() => { (this.state.submittingNewGroup ? console.log("tried to close") : this.setState({ modalOpen: false })) }}>
                    <h1 className="AppFont" style={{color: "white"}}>Create a Group</h1>
                    Group Name: <Input onChange={(e) => { this.setState({ name: e.target.value }) }} />
                    <br />
                    <br />
                    <Button className="AppFont btn-submit" style={{color: "white", backgroundColor: "#F6D55C"}} onClick={this.submitGroupClicked}>Submit</Button>
                    <Button className="AppFont btn-cancel" style={{color: "white", backgroundColor: "#ED553B"}} onClick={this.closeModal.bind(this)}>Cancel</Button>


                </Modal>


                <div className="add-group-button-div">
                    <Button className="add-group-button" style={{ backgroundColor: UserStore.theme().icons, color: UserStore.theme().font2, borderRadius: "40px"}} onClick={this.buttonClicked} size="large"><Icon type="plus" /> Create Group</Button>
                </div>
            </div>
        )
    }
}
export default CreateGroup;
