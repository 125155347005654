import React, { Component } from 'react';
import { Button, Icon, Input, InputNumber, Select, message, Checkbox } from 'antd';
import { observer } from 'mobx-react';
import { addKidAccount, updateProfileImageLocation, editGroups } from '../api_calls/user';
import { sha512 } from 'hash-anything';
import Modal from 'react-modal'
import _ from "underscore";
import ImageUploader from 'react-images-upload';
import AWS from "aws-sdk";
import { FaUserFriends } from 'react-icons/fa';

const allowedImageTypes = ["image/jpeg"];
const maxImageSize = 5000000;
const { Option } = Select;

var albumBucketName = "listgifts-pics";
var bucketRegion = "us-east-2";
var IdentityPoolId = "us-east-2:e467c664-233b-4e2b-ac1f-36d6fb672768";

AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
    })
});

var s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    params: { Bucket: albumBucketName, ContentType: 'image/jpeg' },
    accessKeyId: process.env.AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY
});


@observer(["UserStore", "FamilyStore", "AuthStore", "MyListStore"])
class ChildAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            childAccounts: [],
            options: [],
            createAccountModalOpen: false,
            childGroupModalOpen: false,
            pictures: [],
            birthMonth: 0,
            birthDay: 0,
            uploadingImage: false,
            clickedChild: { name: "", _id: '' },
            checkedGroups: []
        };
        this.onChange = this.onChange.bind(this);
        this.createChildAccountClicked = this.createChildAccountClicked.bind(this);
        this.closeAccountCreationModal = this.closeAccountCreationModal.bind(this);
        this.resetOptions = this.resetOptions.bind(this);
        this.onDayChange = this.onDayChange.bind(this);
        this.onMonthChange = this.onMonthChange.bind(this);
        this.submitRegister = this.submitRegister.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.addPhoto = this.addPhoto.bind(this);

        this.closeChildGroupModal = this.closeChildGroupModal.bind(this);
        this.childGroupsClicked = this.childGroupsClicked.bind(this);

        this.onChildGroupCheckChange = this.onChildGroupCheckChange.bind(this);
        this.submitChildGroupChangeClick = this.submitChildGroupChangeClick.bind(this);
    }

    onChange(value) {
        const { UserStore, MyListStore, FamilyStore } = this.props;
        const activeChild = value;
        if (typeof activeChild === "undefined") {
            UserStore.setChildViewActive(false);
            MyListStore.loadGiftsForUser(UserStore.user._id, UserStore.user._id, FamilyStore.activeGroupId);
        } else {
            UserStore.setChildView(activeChild._id);
            UserStore.setActiveChild(activeChild);
            UserStore.setChildViewActive(true);
        }
    }

    componentDidMount() {
        this.resetOptions();
    }

    createChildAccountClicked() {
        this.setState({ createAccountModalOpen: !this.state.createAccountModalOpen });
    }

    closeAccountCreationModal() {
        this.setState({ createAccountModalOpen: false });
    }

    onDayChange(value) {
        this.setState({ birthDay: value });
    }

    onMonthChange(value) {
        this.setState({ birthMonth: value });
    }

    addPhoto(file, albumName) {
        const { UserStore } = this.props;
        let userId = UserStore.activeChild.id;
        var albumPhotosKey = encodeURIComponent(albumName) + "/";
        var photoKey = albumPhotosKey.concat(userId).concat(".jpg");
        let self = this;

        var upload = s3.upload({
            Key: photoKey,
            Body: file
        });

        var promise = upload.promise();

        promise.then(
            function (data) {
                self.setState({ uploadingImage: false });
                message.info("Successfully uploaded photo!");

                updateProfileImageLocation(data.Location, UserStore.activeChild.id, UserStore.activeChild.jwt).then(() => {
                    self.setState({ pictures: [] });
                    return UserStore.changeProfileImgForChildView(data.Location);
                })
            },
            function (err) {
                message.error("There was an error uploading your photo...");
                console.error(err.message);
            }
        );
    }

    onDrop(picture) {
        const { UserStore } = this.props;
        this.setState({
            drops: this.state.drops + 1
        });
        var selectedFile = this.state.drops - 1 > 0 ? this.state.pictures.concat(picture)[0] : this.state.pictures.concat(picture)[this.state.pictures.concat(picture).length - 1];

        let self = this;
        if (selectedFile && selectedFile.type && allowedImageTypes.includes(selectedFile.type)) {
            var imgtag = document.getElementsByClassName("profile-img")[0];
            if (imgtag) {
                imgtag.title = selectedFile.name;
            }
            if (selectedFile.size && (selectedFile.size < maxImageSize)) {
                this.setState({ uploadingImage: true });
                self.addPhoto(selectedFile, "profile-images");
            } else {
                message.warn("That image is too big!");
            }
        } else if (selectedFile && selectedFile.type && !allowedImageTypes.includes(selectedFile.type)) {
            message.warn("That file type is not supported! Try a '.jpg'");
        } else {
            message.warn("Sorry, that didn't work. Maybe the file is too big or the wrong type");
        }

    }

    submitRegister() {
        const kidData = {
            name: this.state.name,
            email: this.props.UserStore.user.email + "/" + this.state.name,
            hashPass: sha512(this.state.name),
            groups: [{ id: this.props.FamilyStore.activeGroupId, name: this.props.FamilyStore.activeGroupName }],
            birthMonth: this.state.birthMonth,
            birthDay: this.state.birthDay,
            isWelcomeMessageDismissed: true,
            isKidsAccount: true,
            parentAccount: this.props.UserStore.user._id
        }
        let self = this;
        this.setState({ createAccountModalOpen: false });
        addKidAccount(this.props.UserStore.user._id, kidData).then(res => {
            self.props.UserStore.refreshUser(self.props.AuthStore.userAuth.auth.accessToken).then(setTimeout(self.resetOptions, 150));
        })
    }

    closeChildGroupModal() {
        this.setState({ childGroupModalOpen: false })
    }

    resetOptions() {
        let options = [];
        let childAccounts = this.props.UserStore.children;
        if (typeof childAccounts !== "undefined") {
            childAccounts.forEach((kid, idx) => {
                options.push(<Option key={idx} value={kid}>{kid.name}</Option>)
            });
        }
        this.setState({
            options, children: this.props.UserStore.children
        });

    }

    childGroupsClicked(child) {
        this.setState({ childGroupModalOpen: true, clickedChild: { _id: child._id, name: child.name }, checkedGroups: child.groups.map(g => g.id) });
    }

    onChildGroupCheckChange(e) {
        if (this.state.checkedGroups.includes(e.target.id)) {
            this.setState({ checkedGroups: _.filter(this.state.checkedGroups, g => g !== e.target.id) });
        }
        else {
            this.setState({ checkedGroups: this.state.checkedGroups.concat([e.target.id]) });
        }
    }

    submitChildGroupChangeClick() {
        console.log("click");
        console.log(this.state.checkedGroups);
        const groups = this.state.checkedGroups.map((g) => {
            return { id: g, name: _.findWhere(this.props.UserStore.user.groups, { id: g }).name };
        });
        const auth = this.props.AuthStore.userAuth.auth.accessToken;
        const childId = this.state.clickedChild._id;
        editGroups(auth, childId, groups).then((res) => {
            console.log(res);
            if (res.ok) {
                message.success("Groups changed!");
                this.setState({ childGroupModalOpen: false });
                this.props.UserStore.refreshUser(auth, this.props.FamilyStore.activeGroupId);
            }
        });
    }

    render() {
        const {UserStore} = this.props;
        return (
            <div >
                <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                    isOpen={this.state.childGroupModalOpen} onRequestClose={this.closeChildGroupModal}
                >
                    <div style={{ float: "right", fontSize: '28pt' }} onClick={this.closeChildGroupModal}><Icon type="close-circle" /></div>
                    <div className="AppFont" style={{ color: "white", textAlign: 'center' }}>
                        <span style={{ fontSize: '22pt', fontWeight: "bold" }}>{this.state.clickedChild.name}'s Groups</span>
                        <div style={{ textAlign: "left", paddingLeft: '5%', fontSize: '14pt' }}>
                            {this.props.UserStore.user.groups.map(g => <div style={{ paddingBottom: "10px" }}>{g.name}
                                <span style={{ paddingLeft: "15pt" }}><Checkbox
                                    id={g.id}
                                    checked={this.state.checkedGroups.includes(g.id)}
                                    onChange={this.onChildGroupCheckChange.bind(this)}
                                    style={{ color: "white" }}
                                />
                                </span>

                            </div>)}
                        </div>
                        <br />
                        <Button onClick={this.submitChildGroupChangeClick.bind(this)}>Submit</Button>
                    </div>

                </Modal>
                <Modal className="invite-modal" overlayClassName="invite-modal-overlay" style={{ width: "100%", height: "65%" }}
                    isOpen={this.state.createAccountModalOpen} onRequestClose={this.closeAccountCreationModal}
                >
                    <div className="AppFont" style={{ fontSize: "20pt", color: "white" }}>
                        <div onClick={this.closeAccountCreationModal}><Icon type="close-circle" /></div>
                        Name: <Input onChange={(e) => { this.setState({ name: e.target.value }) }} />
                        <br />
                        <span style={{ color: "white", fontSize: "20pt" }}>Birth Day </span>
                        <InputNumber disabled={this.state.disabled} onChange={this.onDayChange} min={1} max={31} size="large" />
                        <br />
                        <br />
                        <span style={{ color: "white", fontSize: "20pt" }}>Birth Month </span>
                        <InputNumber disabled={this.state.disabled} onChange={this.onMonthChange} min={1} max={12} size="large" />
                        <br />
                        <Button onClick={this.submitRegister} size="large" >Submit</Button>
                    </div>
                </Modal>
                <br />
                {this.props.UserStore.user.kids.length === 0 ?
                    <div>
                        <span style={{ color: "white" }}>No Child Accounts Found</span>
                    </div> :
                    <div id="childViewContainer" style={{position: "relative"}}>
                    <Select getPopupContainer={() => document.getElementById("childViewContainer")} defaultValue="Child View Off" size="large" onChange={this.onChange}>
                        <Option value={undefined}>Child View Off</Option>{this.props.UserStore.children.map(kid => <Option value={kid}>{kid.name}</Option>)}</Select>
                    </div>
                }
                <br />
                {this.props.UserStore.childViewActive ?

                    <div style={{ filter: this.state.uploadingImage ? "blur(5px)" : "", pointerEvents: this.state.uploadingImage ? "none" : "" }}>Change Child's Picture <hr />

                        <ImageUploader
                            buttonStyles={{backgroundColor: UserStore.theme().icons}}
                            fileContainerStyle={{backgroundColor: UserStore.theme().background}}
                            label={`Max file size is ${maxImageSize / 1000 / 1000}MB`}
                            withIcon={false}
                            buttonText='Change Child Image'
                            onChange={this.onDrop}
                            imgExtension={['.jpg', '.gif', '.png']}
                            maxFileSize={10000000}
                        /></div> : null}
                <br />
                <Button onClick={this.createChildAccountClicked}>Create Child Account</Button>

                {this.props.UserStore.children.length ? this.props.UserStore.children.map((child) => {
                    return <div style={{ lineHeight: "2em" }}>{child.name} <span onClick={this.childGroupsClicked.bind(this, child)} style={{
                        borderStyle: 'solid',
                        borderWidth: "1px", borderRadius: '3px', float: "right", padding: '2px', fontSize: '11pt', lineHeight: "0.5em"
                    }}><FaUserFriends /></span></div>;
                }) : ""}

            </div>
        )
    }
}
export default ChildAccounts;