import React, { Component } from 'react'
import { Button, Icon, Input, message } from 'antd';
import Modal from 'react-modal';
import { addNote } from '../api_calls/user';
import { observer } from 'mobx-react';

const { TextArea } = Input;
Modal.setAppElement('#root')
@observer(['UserStore', 'AuthStore'])
 class AddNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            note: "",
            modalOpen: false,
            submittingNewNote: false

        };
        this.onSubmitClick = this.onSubmitClick.bind(this);
    }

    onSubmitClick() {
        // console.log("submitting new note..." + this.state.note);
        const { UserStore, AuthStore } = this.props;
        let self = this;
        self.setState({ submittingNewNote: true });
        let user = UserStore.childViewActive ? UserStore.activeChild.id : UserStore.user._id;
        addNote(user, this.state.note).then(() => {
            message.success("Note added");
            self.setState({ submittingNewNote: false, modalOpen: false });
            UserStore.refreshUser(AuthStore.userAuth.auth.accessToken);            
        });
    }

    render() {
        return (<div>
            <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                isOpen={this.state.modalOpen}
                onRequestClose={() => { (this.state.submittingNewNote ? console.log("tried to close") : this.setState({ modalOpen: false })) }}>
                <div style={{textAlign: "center"}}> 
                    <span style={{ fontSize: "16pt", color: "#3CAEA3" }} className="AppFont">New Note</span>
                    <br />
                    <TextArea onChange={(e) => { this.setState({ note: e.target.value }) }} placeholder="I always like..." autosize />
                    <br />
                    <br />
                    <Button className="AppFont btn-submit" style={{color: "white", backgroundColor: "#F6D55C"}} onClick={this.onSubmitClick.bind(this)} size="large"><Icon type="check-circle" /> Submit</Button>
                    <Button className="AppFont btn-cancel" style={{color: "white", backgroundColor: "#ED553B"}} onClick={() => { this.setState({ modalOpen: false }) }} size="large"><Icon type="close" /> Cancel</Button>
                </div>
            </Modal>
            <div>
                <div style={this.props.divStyle} onClick={() => this.setState({ modalOpen: true })} size="large">
                    <Icon style={{fontSize: "14pt", paddingRight: "4px"}} type="file-add" />Add Note</div>
            </div>
        </div>
        )
    }
}
export default AddNote;