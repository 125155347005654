import React, { Component } from 'react'
import { List } from "antd";
export default class ViewNotes extends Component {
    render() {
        const listItems = this.props.notes.map((note, key) =>
            <div style={{ fontSize: "18pt", color: "white" }}>
                - {note}
                <br />
            </div>);
        return (
            <div className="NoteFont" style={{ paddingLeft: '45px', paddingRight: '45px', paddingTop: '15px' }}>
                <List
                    size="large"
                    header={<div style={{ fontSize: "20pt", color: "white" }}>Notes</div>}
                    style={{ background: "rgba(0, 0, 0, 0.35)" }}
                    bordered
                    dataSource={listItems}
                    renderItem={item => (<List.Item style={{ padding: "15px" }}>{item}</List.Item>)}
                />
            </div>
        )
    }
}
