import React, { Component } from 'react';
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import { Icon, Button, Input, message, List } from 'antd';
import { sha512 } from 'hash-anything';
import { createUser } from "../api_calls/user";
import _ from "underscore";
const headerFontSize = "14pt";

Modal.setAppElement('#root')
@observer(["UserStore"])
class RegisterButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            hashPass: "",
            family: "",
            registerModalIsOpen: false,
            loginModalIsOpen: false,
            forgotModalIsOpen: false,
            buttons: <div>
                <Button style={_.extend(this.props.style || {}, { marginLeft: "4px", backgroundColor: "#F6D55C", borderRadius: "40px", color: 'white', fontWeight: "bold" })} size="large" onClick={() => { this.setState({ registerModalIsOpen: true, loginModalIsOpen: false }) }}>Sign Up</Button>
            </div>
        };
       
        this.closeRegisterModal = this.closeRegisterModal.bind(this);
        this.onFamilyClick = this.onFamilyClick.bind(this);       
        this.submitRegister = this.submitRegister.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
    }

    closeRegisterModal() {
        this.setState({ registerModalIsOpen: false });
    }

    componentDidMount() {
        document.addEventListener("keyup", event => {
            if (event.key !== "Enter") return;

            if (this.state.registerModalIsOpen) {
                this.submitRegister();
            }    
        });
    }

    submitLogin() {
        const { onLoggedIn, UserStore } = this.props;
        let self = this;
        let email = this.state.email;
        let passHash = this.state.hashPass;
        if (this.state.email === "") {
            message.warning("Must have email");
        } else if (this.state.hashPass === "") {
            message.warning("Must have a password");
        } else {
            this.setState({
                loginModalIsOpen: false,
                buttons: <Icon type="loading" />
            });
            fetch(`${process.env.REACT_APP_BACKEND_URL}/auth`, {
                body: JSON.stringify({ email, passHash }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }).then((response) => {
                if (response.status === 401 || response.status === 403) {
                    return message.error("Email or password does not match database!");
                } else {
                    return response.json().then((res) => UserStore.handleLoggedIn(res));
                }
            })
                .catch(err => {
                    window.alert(err);
                    this.setState({ loading: false });
                });

            this.setState({
                buttons: <div>
                    <Button style={{ marginLeft: "4px", backgroundColor: "#a1e0ff", borderRadius: "40px", color: 'white', fontWeight: "bold" }} size="large" onClick={() => { self.setState({ loginModalIsOpen: true, registerModalIsOpen: false }) }}>Log In</Button>
                </div>
            });
        }
    }

    submitRegister() {
        let self = this;
        console.log("Registering...");

        if (this.state.email === "") {
            message.warning("Must have email");
        } else if (this.state.name === "") {
            message.warning("Please do not leave name blank");
        } else if (this.state.hashPass === "") {
            message.warning("Must have a password");
        } else {
            this.setState({
                registerModalIsOpen: false,
                buttons: <Icon type="loading" />
            });
            createUser(
                this.state.name,
                this.state.email,
                this.state.hashPass,
                this.state.family
            ).then((response) => {
                self.submitLogin();
            });
        }
    }

    onFamilyClick(event) {
        this.setState({ family: event.key });
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.registerModalIsOpen}
                    onRequestClose={this.closeRegisterModal}
                    contentLabel="Register Modal"
                    className="register-modal"
                    overlayClassName="register-modal-overlay">
                    <div className="AppFont" style={{ fontSize: headerFontSize, color: "white", textAlign: "center" }}>
                        Name <Input style={{ textAlign: "center" }} onChange={(e) => { this.setState({ name: e.target.value }) }} />
                        <br />
                Email <Input style={{ textAlign: "center" }} type="email" onChange={(e) => { this.setState({ email: e.target.value }) }} />
                        <br />
                Password <Input id="password-register" style={{ textAlign: "center" }} type="password" onChange={(e) => { this.setState({ hashPass: sha512(e.target.value) }) }} />
                        <br />
                        <br />
                        <br />
                        <Button className="btn-submit" id="submitBtn-register" onClick={this.submitRegister} size="large" >Submit</Button>
                    </div>
                </Modal>
                {this.state.buttons}
            </div>
        );
    }
}

export default RegisterButton;