import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { observer } from "mobx-react";
import { FaReceipt } from "react-icons/fa";
import { deleteReceipt, createReceipt } from "../api_calls/receipt.js";
import _ from "underscore";
import { Button, Icon, Popconfirm } from 'antd';
import EditReceiptModal from './EditReceiptModal.js';
import AddReceiptModal from './AddReceiptModal.js';

@observer(["UserStore", "FamilyStore", "MyListStore", "AuthStore"])
class BoughtHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editModalOpen: false,
            columns: [{
                sortable: false,
                name: 'Gift',
                selector: 'name',
                cell: row => row._id === "totals" ? <div style={{ fontWeight: "bold" }}>{row.name}</div> : <div>{row.name}</div>
            },
            {
                sortable: true,
                selector: 'for',
                name: 'For'
            },
            {
                sortable: true,
                selector: 'price',
                name: 'Price',
                cell: row => row._id === "totals" ? <div style={{ fontWeight: "bold" }}>${row.price.toFixed(2)}</div> : <div>${row.price.toFixed(2)}</div>
            },
            {
                sortable: true,
                name: "Date Bought",
                selector: 'date',
                sortFunction: (rowA, rowB) => rowA.timePurchased - rowB.timePurchased
            },
            {
                sortable: false,
                name: 'Actions',
                selector: 'actions',
                cell: row => row._id === "totals" ? "" : <div>
                    <Popconfirm title="Are you sure you want to delete this receipt?" onConfirm={this.confirmDeleteReceipt.bind(this, row._id)} okText="Yes" cancelText="No">
                        <Icon style={{ fontSize: "22pt" }} type="delete" />
                    </Popconfirm>
                    <Icon style={{ fontSize: "22pt" }} onClick={this.showEditModal.bind(this, row)} type="edit" />
                </div>
            }]
        };
        this.confirmDeleteReceipt = this.confirmDeleteReceipt.bind(this);
        this.showEditModal = this.showEditModal.bind(this);
        this.makeReceipt = this.makeReceipt.bind(this);
    }

    componentDidMount() {
        this.props.MyListStore.loadReceipts(this.props.AuthStore.userAuth.auth.accessToken);
    }

    showEditModal(row) {
        this.setState({ editModalOpen: true, editReceipt: _.findWhere(this.props.MyListStore.receipts, { _id: row._id }) });
    }

    confirmDeleteReceipt(id) {
        const { MyListStore } = this.props;
        const token = this.props.AuthStore.userAuth.auth.accessToken;
        deleteReceipt(id, token).then((res) => {
            MyListStore.loadReceipts(token);
        });
    }

    makeReceipt(g) {
        let gift = this.props.MyListStore.getGift(g)
        const token = this.props.AuthStore.userAuth.auth.accessToken;
        let thisGift = JSON.parse(JSON.stringify(gift));
        thisGift.giftId = gift._id;
        delete thisGift._id;
        delete thisGift.groups;
        delete thisGift.family;
        thisGift.wisher = this.props.UserStore.getUser(gift.wisher) ? this.props.UserStore.getUser(gift.wisher).name : gift.wisher;
        thisGift.userId = this.props.UserStore.user._id;
        thisGift._id = `${thisGift.giftId}${thisGift.userId}`;
        createReceipt(token, thisGift).then((res) => {
            if (res && res.status && (res.status === 200)) {
                this.props.MyListStore.loadReceipts(token);
            }
        });
    }

    render() {
        const { MyListStore, FamilyStore, UserStore } = this.props;
        let giftsIBought = _.filter(MyListStore.gifts, g =>
            (g.family ? g.family === FamilyStore.activeGroupId : g.groups.includes(FamilyStore.activeGroupId)) &&
            (g.purchaser === UserStore.user.name || g.purchaser === UserStore.user._id));
        let giftsWithoutReceipts = _.difference(giftsIBought.map(g => g._id), MyListStore.receipts.map(r => r.giftId));
        const tableData = MyListStore.receipts.map((g) => {
            return {
                _id: g._id,
                name: g.title,
                timePurchased: g.timePurchased,
                date: new Date(g.timePurchased).toLocaleDateString(),
                price: g.price,
                for: _.findWhere(FamilyStore.family, { _id: g.wisher }) ? _.findWhere(FamilyStore.family, { _id: g.wisher }).name : g.wisher
            }
        });
        const totalsRow = [{
            _id: "totals",
            name: "Total",
            price: _.reduce(tableData.map(d => d.price), (m, n) => m + n, 0)
        }]
        return (<div>
            <EditReceiptModal rct={this.state.editReceipt} modalOpen={this.state.editModalOpen} closeModalCallback={() => { this.setState({ editModalOpen: false }) }} />
            <AddReceiptModal modalOpen={this.state.addRctModalOpen} closeModalCallback={() => { this.setState({ addRctModalOpen: false }) }} />
            <Button style={{ backgroundColor: UserStore.theme().icons, color: UserStore.theme().font2,  borderRadius: "40px"}} onClick={() => {this.setState({addRctModalOpen: true}) }} size="large"><Icon type="plus" /> Add Receipt</Button>
            
            <DataTable
                defaultSortField="for"
                defaultSortAsc={true}
                columns={this.state.columns}
                data={tableData.concat(totalsRow)}
            />
            {giftsWithoutReceipts && giftsWithoutReceipts.length ? <div>
                <div className="AppFont" style={{ color: "white" }}>Turn these purchases into receipts?</div>
                <div style={{ display: "inline-grid" }}>
                    {giftsWithoutReceipts.map((gift) => <div style={{ backgroundColor: "rgba(255,255,255,0.4)", marginBottom: "5px", padding: "7px", borderRadius: "4px" }}>
                        {MyListStore.getGift(gift) ? MyListStore.getGift(gift).title : gift}
                        <div>
                            <Button onClick={this.makeReceipt.bind(this, gift)}><FaReceipt style={{ marginRight: '5px' }} /> Make Receipt</Button>
                        </div>
                    </div>)}</div>
            </div> : ''}
        </div>
        );
    }
}

export default BoughtHistory;