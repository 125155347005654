import React, { Component } from 'react';
import { sha512 } from 'hash-anything';
import { Button, Input, message } from 'antd';
import { observer } from 'mobx-react';
import { resetPassword } from "../api_calls/user";
import { FaCheckCircle } from 'react-icons/fa'

@observer(['GuestRouter'])
class ResetPassword extends Component {
    constructor(props) {
        super(props);

        this.submitReset = this.submitReset.bind(this);
        this.state = {
            display: <div className="reset-pass-title" >
                <h1 style={{ display: "initial", borderRadius: "7px", color: "#3CAEA3", backgroundColor: "rgba(0,0,0,0.5)", paddingLeft: "7px", paddingRight: "7px" }}>Reset Password</h1>
                <br />
                <span style={{ fontSize: "16pt", color: "white" }} className="AppFont">New Password</span>
                <br />
                <Input style={{ textAlign: "center", width: "80%" }} className="password-spoof" onChange={(e) => { this.setState({ newHashPass: sha512(e.target.value) }) }} />
                <br />
                <span style={{ fontSize: "16pt", color: "white" }} className="AppFont">Confirm New Password</span>
                <br />
                <Input style={{ textAlign: "center", width: "80%" }} className="password-spoof" onChange={(e) => { this.setState({ newHashPassConfirm: sha512(e.target.value) }) }} />
                <br />
                <br />
                <Button onClick={this.submitReset}>Submit</Button>
            </div>
        };
    }
    componentDidMount() {
        console.log(this.props.GuestRouter.router.params.resetLink);
    }
    submitReset(e) {

        const auth = this.props.GuestRouter.router.params.resetLink;
        const newPassHash = this.state.newHashPass;
        const newPassHashConf = this.state.newHashPassConfirm;

        if (!newPassHash || !newPassHashConf) {
            message.warn("Please complete all fields");
        }
        else if (newPassHashConf !== newPassHash) {
            message.warn("New password and confirmation do not match!");
        }
        else {
            resetPassword(newPassHash, auth).then((res) => {
                this.setState({
                    display: <div className="AppWelcome">Password Reset!
               <div>
               <FaCheckCircle />
               <div><a style={{borderRadius: "7px", backgroundColor: "white", paddingLeft: "7px", paddingRight: "7px"}} href={process.env.REACT_APP_FRONTEND_URL}>SIGN IN</a></div>
                        </div>
                    </div>
                })
            });
        }
    }
    render() {
        return (this.state.display);
    }
}

export default ResetPassword;