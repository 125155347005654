import axios from "axios";
const url = process.env.REACT_APP_BACKEND_URL;

export function createLog(auth, newLog) {
    axios.post(url + "/session-logs", newLog, {
        headers: {
            Authorization: `Bearer ${auth}`
        }
    })
    .then(function (response) {
        return response;
    })
    .catch(function (error) {
        console.log(error);
    });
}

export function sessionEnd(newLog) {
    navigator.sendBeacon(url + "/session-logs", newLog);
}
