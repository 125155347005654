import React from 'react';
import { Suspense } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import * as serviceWorker from './serviceWorker';
import ViewStore from './stores/ViewStore'
import AuthStore from './stores/AuthStore'
import FamilyStore from './stores/FamilyStore'
import CustomRouter from './stores/CustomRouter'
import GuestRouter from './stores/GuestRouter'
import UserStore from './stores/UserStore'
import MyListStore from './stores/MyListStore'
import { config as dotEnvConfig } from 'dotenv';
// Mobx Router
import { Provider } from "mobx-react"



// Load ENV variables
dotEnvConfig({
  path: process.env.NODE_ENV === 'production' ? '.env.production' : '.env'
});


// const app = (
//   <div>We are currently undergoing maintenance, please check back later</div>
// );

const app = (
  <Suspense fallback={null}>
  <Provider
    ViewStore={ViewStore}
    AuthStore={AuthStore}
    CustomRouter={CustomRouter}
    GuestRouter={GuestRouter}
    UserStore={UserStore}
    MyListStore={MyListStore}
    FamilyStore={FamilyStore}
  >
    <App />
  </Provider>
  </Suspense>
);

ReactDOM.render(app, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
