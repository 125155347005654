import axios from "axios";
import { message } from "antd";

const url = process.env.REACT_APP_BACKEND_URL;

export async function getEvents(params) {
    return axios({
        method: 'get',
        url: url + '/events/' + params.groupId,
        headers: {
            Authorization: `Bearer ${params.token}`
        }
    }).then((res,err) => {
        if (err) {
            message.error("Error getting events...");
            return err;
        }
        return res;
    }).catch((e) => {
        if (e) {
            message.error("Error getting events...");
            return e;
        }
    });
}

export async function deleteEvent(eventId, token) {
    return axios({
        method: 'delete',
        url: url + "/events/" + eventId,
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(res => {
        return res;
    });
}


export async function editEvent(eventId, accessToken, mongoUpdate) {
    return axios.put(url + "/events/update/" + eventId, { mongoUpdate }, {
        headers: { Authorization: `Bearer ${accessToken}` }
    })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            return error;
        });
}

export async function createEvent(auth, evt) {
    return axios.post(url + "/events", evt, {
        headers: {
            Authorization: `Bearer ${auth}`
        }
    })
        .then(function (response) {
            message.success("Success, event added!");
            return response;
        })
        .catch(function (error) {
            message.error("Error adding event.");
            console.log(error);
        });
}