import React, { Component } from 'react'
import { List } from "antd";
export default class ViewPrivateNotes extends Component {
    render() {
        console.log(this.props.notes);

        const listItems = this.props.notes && this.props.notes.length ? this.props.notes.map((note, key) =>
            <div style={{ fontSize: "18pt", color: "white" }}>
                {note.body}
                <br />
            </div>) : [];
        return (
            <div className="NoteFont" style={{ paddingLeft: '45px', paddingRight: '45px', paddingTop: '15px' }}>
                <List
                    size="large"
                    header={<div style={{ fontSize: "20pt", color: "white" }}>My Notes About {this.props.user}</div>}
                    style={{ background: "rgba(0, 0, 0, 0.35)", backgroundColor: "#598b59" }}
                    bordered
                    dataSource={listItems}
                    renderItem={item => (<List.Item style={{ padding: "15px" }}>{item}</List.Item>)}
                />
            </div>
        )
    }
}
