import React, { Component } from 'react'
import { observer } from 'mobx-react';
import NavViews from '../stores/NavViews';
import {joinGroup, getGroupName} from "../api_calls/user";
import { Icon } from 'antd';


@observer(['CustomRouter', 'UserStore', "ViewStore", "AuthStore"])
class InvitePage extends Component {
    constructor(props) {
        super(props);
        // const { router: { goTo } } = this.props.CustomRouter;
        // if (this.props.UserStore.user._id !== "guest") {
        //     this.props.CustomRouter.router.goTo(NavViews.home);
        // }
        this.state = {groupName: ""}
        let self = this;
        getGroupName(this.props.CustomRouter.router.params.inviteId).then((groupName) => {
            self.setState({groupName});
            joinGroup(this.props.UserStore.user._id, this.props.AuthStore.userAuth.auth.accessToken, this.props.CustomRouter.router.params.inviteId, groupName).then((res) => {
                self.props.CustomRouter.router.goTo(NavViews.home); 
                self.props.UserStore.refreshGroups();
            });
        })
      
    }
    render() {
       
        return (
            this.props.UserStore.user._id !== "guest" ?
            <div>
                Welcome to list.gifts! You've been invited to join {this.state.groupName} group!
                <br />
                <Icon type="loading"/>
                
            </div> : this.props.CustomRouter.router.goTo(NavViews.home)
        )
    }
}
export default InvitePage;
