export const menu = (styles) => {
    return {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            left: '2px',
            top: '2px',
            color: styles.bmBurgerBars.backgroundColour || '#373a47'
        },
        bmBurgerBars: {
            background: styles.bmBurgerBars.backgroundColour || '#373a47'
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px'
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            backgroundColor: styles.bmItem.backgroundColour || 'white',
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad',
            padding: '0.8em',
        },
        bmItem: {
            display: 'block',
            paddingBottom: '2em',
            paddingTop: '2em',
            width: "100%",
            borderStyle: 'solid',
            borderRight: 'none',
            borderLeft: 'none',
            borderTop: 'none',
            borderColour: styles.bmItem.borderColour || 'black',
            borderWidth: '1px',
            backgroundColor: styles.bmItem.backgroundColour || 'white'
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    }
}

export const settings = (styles) => {
    return {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            right: '22px',
            top: '2px',
            color: styles.bmBurgerBars.backgroundColour || '#373a47'
        },
        bmBurgerBars: {
            background: styles.bmBurgerBars.backgroundColour || '#373a47'
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '24px',
            width: '24px',
            right: "16px"
        },
        bmCross: {
            background: '#bdc3c7'
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            backgroundColor: styles.bmItem.backgroundColour || "white",
            padding: '2.5em 1.5em 0',
            fontSize: '1.15em'
        },
        bmMorphShape: {
            fill: '#373a47'
        },
        bmItemList: {
            color: '#b8b7ad',
            padding: '0.8em'
        },
        bmItem: {
            display: 'block',
            backgroundColor: styles.bmItem.backgroundColour || "white"
        },
        bmOverlay: {
            background: 'rgba(0, 0, 0, 0.3)'
        }
    }
}