import axios from "axios";
import { message } from "antd";

const url = process.env.REACT_APP_BACKEND_URL;


export async function getReceipts(auth) {
    return axios({
        method: 'get',
        url: url + '/receipts',
        headers: {
            Authorization: `Bearer ${auth}`
        }
    }).then(res => {
        return res.data;
    });
}

export async function deleteReceipt(receiptId, token) {
    return axios({
        method: 'delete',
        url: url + "/receipts/" + receiptId,
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(res => {
        return res.data;
    });
}


export async function editReceipt(rctId, accessToken, mongoUpdate) {
    return axios.put(url + "/receipts/update/" + rctId, {mongoUpdate}, {
        headers: { Authorization: `Bearer ${accessToken}` }
    }).then(function (response) {
        return response;
    })
        .catch(function (error) {
            return error;
        });

}

export async function createReceipt(auth, rct) {
    return axios.post(url + "/receipts", rct, {
        headers: {
            Authorization: `Bearer ${auth}`
        }
    })
        .then(function (response) {
            message.success("Success, receipt added!");
            return response;
        })
        .catch(function (error) {
            message.error("Error adding receipt.");
            console.log(error);
        });
}
