import React, { Component } from 'react'
import { observer } from 'mobx-react';
import MyFamily from './MyFamily';
import CreateGroup from './CreateGroup';

@observer(["UserStore", "FamilyStore"])
class Groups extends Component {
    
    render() {
        return (
            <div>
                {/* <JoinGroup /> */}
                <CreateGroup />
                
                {this.props.UserStore.user.groups.length === 0 ?
                    <div className="AppFont" style={{ fontWeight: "bold", fontSize: "34pt", color: "white" }}>No Group Found
                    <br />
                    </div> :
                    <MyFamily groupId={this.props.FamilyStore.activeGroupId} />
                }
            </div>
        )
    }
}
export default Groups;