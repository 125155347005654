import React, { Component } from 'react';
import Modal from 'react-modal';
import { message, Button, Icon, Input, InputNumber, Popconfirm, Card, List, Menu, Dropdown } from 'antd';
import { FaTicketAlt, FaUtensils, FaDAndD, FaCampground, FaRegGem, FaGamepad, FaChess, FaTv, FaBlender, FaBath, FaHome, FaTableTennis, FaStar } from 'react-icons/fa'
import { deleteGift, editGift } from '../api_calls/gift';
import { observer } from 'mobx-react';
import _ from 'underscore';
import FamilyStore from '../stores/FamilyStore';
const { TextArea } = Input;
const { Meta } = Card;

const colourMap = {
    "1" : "gold",
    "2": "#1890ff",
    // "3": "bronze"
}

Modal.setAppElement('#root')
@observer(['AuthStore', 'UserStore', 'MyListStore', 'FamilyStore'])
class GiftCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            description: "",
            link: "",
            imageLink: "",
            price: 0,
            modalOpen: false,
            giftDescriptionModalOpen: false,
            submittingEdits: false,
            dropdownDisplay: <span><Icon type="gift" style={{ fontSize: "35pt" }} /> <Icon type="down" /></span>,
            testImg: ""
        };
        this.confirmDelete = this.confirmDelete.bind(this);
        this.showEditModal = this.showEditModal.bind(this);
        this.onSubmitEdits = this.onSubmitEdits.bind(this);
        this.padded = this.padded.bind(this);
        this.determineCategoryIcon = this.determineCategoryIcon.bind(this);
        // this.getGiftInfo("https://cors-anywhere.herokuapp.com/https://en.wikipedia.org/wiki/George_Washington");
    }

    confirmDelete(gift) {
        const { UserStore, MyListStore, FamilyStore } = this.props;
        deleteGift(gift).then(() => {
            MyListStore.loadGiftsForUser(UserStore.user._id, UserStore.user._id, FamilyStore.activeGroupId);
        });
    }

    showEditModal(gift) {
        this.setState({
            modalOpen: true,
            id: gift._id,
            title: gift.title,
            description: gift.description,
            category: gift.category,
            link: gift.link,
            price: gift.price,
            dropdownDisplay: this.determineCategoryIcon(gift.category)
        });
    }

    onSubmitEdits() {
        let self = this;
        self.setState({ submittingEdits: true });
        editGift(this.state.id, this.state.title, this.state.description, this.state.link, this.state.category, this.state.price).then(() => {
            self.setState({ submittingEdits: false, modalOpen: false });
            self.props.MyListStore.loadGiftsForUser(self.props.UserStore.user._id, self.props.UserStore.user._id, self.props.FamilyStore.activeGroupId);
            message.success("Edits sumbitted");
        });
    }

    padded(stringToPad, gift) {
        let teaserStringLength = 30;
        let teaserString;
        if (stringToPad.length === 0) {
            return <span><br /><br /></span>;
        }
        else if (stringToPad.length <= teaserStringLength) {
            return <span>{stringToPad}</span>;
        } else {
            teaserString = stringToPad.slice(0, teaserStringLength) + "...";
            return <Button onClick={() => {
                this.setState({
                    descriptionTitle: gift.title,
                    descriptionText: stringToPad,
                    descPrice: gift.price,
                    giftDescriptionModalOpen: true
                })
            }}>{teaserString}</Button>;
        }
    }

    determineCategoryIcon(category, chosenFontSize = 70) {
        let icon;
        let iconFontSize = String(chosenFontSize).concat("pt");
        switch (category) {
            case ("books"):
                icon = <Icon type="book" style={{ fontSize: iconFontSize }} />;
                break;
            case ("camping"):
                icon = <FaCampground style={{ fontSize: iconFontSize }} />;
                break;
            case ("clothing"):
                icon = <Icon type="skin" style={{ fontSize: iconFontSize }} />;
                break;
            case ("tools"):
                icon = <Icon type="tool" style={{ fontSize: iconFontSize }} />;
                break;
            case ("art"):
                icon = <Icon type="picture" style={{ fontSize: iconFontSize }} />;
                break;
            case ("food"):
                icon = <FaUtensils style={{ fontSize: iconFontSize }} />;
                break;
            case ("appliances"):
                icon = <FaBlender style={{ fontSize: iconFontSize }} />;
                break;
            case ("cosmetics"):
                icon = <FaBath style={{ fontSize: iconFontSize }} />;
                break;
            case ("technology"):
                icon = <FaTv style={{ fontSize: iconFontSize }} />;
                break;
            case ("household"):
                icon = <FaHome style={{ fontSize: iconFontSize }} />;
                break;
            case ("board games"):
                icon = <FaChess style={{ fontSize: iconFontSize }} />;
                break;
            case ("video games"):
                icon = <FaGamepad style={{ fontSize: iconFontSize }} />;
                break;
            case ("jewelry"):
                icon = <FaRegGem style={{ fontSize: iconFontSize }} />;
                break;
            case ("gift cards"):
                icon = <Icon type="credit-card" style={{ fontSize: iconFontSize }} />;
                break;
            case ("tickets"):
                icon = <FaTicketAlt style={{ fontSize: iconFontSize }} />;
                break;
            case ("d&d"):
                icon = <FaDAndD style={{ fontSize: iconFontSize }} />;
                break;
            case ("toys"):
                icon = <FaTableTennis style={{ fontSize: iconFontSize }} />;
                break;
            default: icon = <Icon type="gift" style={{ fontSize: iconFontSize }} />;
        }
        return icon;
    }

    handleClick = e => {
        this.setState({
            current: e.key,
        });
        switch (e.key) {
            case ("item_0"):
                this.setState({ category: "books", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Books</span> <Icon type="book" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_1"):
                this.setState({ category: "camping", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Camping</span> <FaCampground style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_2"):
                this.setState({ category: "clothing", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Clothing</span> <Icon type="skin" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_3"):
                this.setState({ category: "tools", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Tools</span> <Icon type="tool" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_4"):
                this.setState({ category: "art", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Art</span> <Icon type="picture" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_5"):
                this.setState({ category: "food", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Food</span> <FaUtensils style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_6"):
                this.setState({ category: "appliances", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Appliances</span> <FaBlender style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_7"):
                this.setState({ category: "cosmetics", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Cosmetics</span> <FaBath style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_8"):
                this.setState({ category: "technology", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Technology</span> <FaTv style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_9"):
                this.setState({ category: "household", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Household</span> <FaHome style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_10"):
                this.setState({ category: "board games", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Board Games</span> <FaChess style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_11"):
                this.setState({ category: "video games", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Video Games</span> <FaGamepad style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_12"):
                this.setState({ category: "jewelry", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Jewelry</span> <FaRegGem style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_13"):
                this.setState({ category: "gift cards", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Gift Cards</span> <Icon type="credit-card" style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_14"):
                this.setState({ category: "tickets", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Tickets</span> <FaTicketAlt style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_15"):
                this.setState({ category: "d&d", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Dungeons and Dragons</span> <FaDAndD style={{ fontSize: "35pt" }} /></span> });
                break;
            case ("item_16"):
                this.setState({ category: "toys", dropdownDisplay: <span><span style={{ fontSize: "24pt" }}>Toys</span> <FaTableTennis style={{ fontSize: "35pt" }} /></span> });
                break;

            default: this.setState({ dropdownDisplay: <span><Icon type="gift" style={{ fontSize: "35pt" }} /> <Icon type="down" /></span> });
        }
    }

    render() {
        const {UserStore} = this.props;
        const imageMenu = (
            <Menu onClick={this.handleClick}>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Books</span> <Icon type="book" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Camping</span> <FaCampground style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Clothing</span> <Icon type="skin" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Tools</span> <Icon type="tool" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Art</span> <Icon type="picture" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Food</span> <FaUtensils style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Appliances</span> <FaBlender style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Cosmetics</span> <FaBath style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Technology</span> <FaTv style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Household</span> <FaHome style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Board Games</span> <FaChess style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Video Games</span> <FaGamepad style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Jewelry</span> <FaRegGem style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Gift Cards</span> <Icon type="credit-card" style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Tickets</span> <FaTicketAlt style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Dungeons and Dragons</span> <FaDAndD style={{ fontSize: "35pt" }} />
                </Menu.Item>
                <Menu.Item>
                    <span style={{ fontSize: "24pt" }}>Toys</span> <FaTableTennis style={{ fontSize: "35pt" }} />
                </Menu.Item>
            </Menu>
        );
        let gifts = this.props.gifts;
        if (this.props.sort && FamilyStore.activeGroup && FamilyStore.activeGroup.settings.allowRankings) {
            gifts.forEach((gift) => {
                delete gift.rank;
                if (this.props.ranks[gift._id]) {
                    gift.rank = this.props.ranks[gift._id];
                }
             });
            gifts = _.sortBy(gifts, thisGift => thisGift.rank || Infinity);
        }
        else {
            gifts.forEach((gift) => {
                delete gift.rank;
            })
            gifts = _.sortBy(gifts, thisGift => Math.random()*100);
        }
        const listItems = gifts.map((gift) =>
            <List.Item key={gift._id.toString()} style={{ padding: "15px", backgroundColor: UserStore.theme().appBackground }}>
                <Card
                    actions={[<Icon style={{ fontSize: "22pt" }} onClick={this.showEditModal.bind(this, gift)} type="edit" />,
                    <Popconfirm title="Are you sure you want to delete this gift?" onConfirm={this.confirmDelete.bind(this, gift._id)} okText="Yes" cancelText="No">
                        <Icon style={{ fontSize: "22pt" }} type="delete" />
                    </Popconfirm>]}
                    cover={gift.link !== "" ?
                        <div>
                            <a href={gift.link}> {gift.imageLink === "" ? this.determineCategoryIcon(gift.category) :
                                <img width="95px" height="95px" alt="Could Not Load" src={gift.imageLink} />}
                            </a>
                            <br />
                            {gift.imageLink === "" ? null : <div> {this.determineCategoryIcon(gift.category, 20)}
                                <span style={{ fontSize: "18pt" }}>{gift.category.toUpperCase()}</span>
                            </div>}
                        </div>
                        : this.determineCategoryIcon(gift.category)}
                    // cover={<a href={gift.link}>
                    //     {/* {console.log("BASE URI: " + (gift.link !== "" && gift.link !== document.baseURI ? new URL(gift.link).origin : "this host"))} */}
                    //     {/* <GiftImage gift={gift} /> */}
                    //     {gift.imageLink === "" ? this.determineCategoryIcon(gift.category) :
                    //         <img width="256px" height="256px" alt="Image Could Not Load" src={gift.imageLink === "" ? require("../assets/gift-flat.png") :
                    //             gift.imageLink} />}
                    // </a>}
                    bordered={false}
                    style={{ backgroundColor: "rgb(200,200,200)", height: "27em" }}
                // onClick={() => { gift.link !== "" ? window.location = gift.link : console.log("card clicked " + gift._id) }}
                >
                    <span style={{ fontSize: "24pt", color: "#1890ff" }}> ${gift.price.toFixed(2)}</span> <br />
                    <Meta
                        //   avatar={<Avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />}
                        avatar={gift.rank ? <span style={{position: "absolute", top: '10px', fontSize: 26, paddingLeft: "8px",paddingRight: "8px", borderRadius: "7px", backgroundColor: colourMap[gift.rank]}}>
                            {gift.rank} {gift.rank === 1 ? <FaStar />  :""}
                            </span> : ""}
                        title={gift.title}
                        description={this.padded(gift.description, gift)}
                    // style={{ fontSize: "12pt" }}
                    />
                </Card>
            </List.Item>
        );
        return (
            <div style={{backgroundColor: UserStore.theme().appBackground || "white"}}>
                <Modal className="register-modal" overlayClassName="register-modal-overlay" style={{ width: "100%", height: "65%" }}
                    isOpen={this.state.modalOpen}
                    onRequestClose={() => { (this.state.submittingEdits ? console.log("tried to close") : this.setState({ modalOpen: false })) }}>
                    {(!this.state.submittingEdits ?
                        <div style={{ marginLeft: "4px" }}>

                            <h2 style={{ color: "white" }} className="AppFont">Gift Details</h2>
                            <span style={{ fontSize: "16pt" }} className="AppFont">Title:</span>
                            <br />
                            <Input maxLength={30} value={this.state.title} onChange={(e) => { this.setState({ title: e.target.value }) }} placeholder="Brand Name Widget" />
                            <br />
                            <span style={{ fontSize: "16pt" }} className="AppFont">Description:</span>
                            <br />
                            <TextArea value={this.state.description} onChange={(e) => { this.setState({ description: e.target.value }) }} placeholder="This is a..." autosize />
                            <br />
                            <span style={{ fontSize: "16pt" }} className="AppFont">Price:</span>
                            <br />
                            <InputNumber
                                defaultValue={this.state.price}
                                min={0}
                                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                onChange={(value) => { this.setState({ price: value }) }}
                            />
                            <br />
                            <span style={{ fontSize: "16pt" }} className="AppFont">Link:</span>
                            <br />
                            <Input value={this.state.link} onChange={(e) => { this.setState({ link: e.target.value }) }} placeholder="https://......." />
                            <br />
                            <span style={{ fontSize: "16pt" }} className="AppFont">Category:</span>
                            <br />
                            <Dropdown overlay={imageMenu}>
                                {this.state.dropdownDisplay}
                            </Dropdown>
                            {/* <Input value={this.state.imageLink} onChange={(e) => { this.setState({ imageLink: e.target.value }) }} placeholder="https://......." /> */}
                            <br />
                            <br />
                            <Button onClick={this.onSubmitEdits} size="large"><Icon type="check-circle" /> Submit Edits</Button>
                            <Button onClick={() => { this.setState({ modalOpen: false }) }} size="large"><Icon type="close" /> Cancel</Button>
                        </div>

                        : <Icon type="loading" style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: 'translate("-50%", "-50%")', fontSize: "50pt"
                        }} />)}
                </Modal>
                <Modal className="gift-description-modal" overlayClassName="gift-description-modal-overlay"
                    isOpen={this.state.giftDescriptionModalOpen}
                    onRequestClose={() => { this.setState({ giftDescriptionModalOpen: false }) }}>
                    <div style={{ textAlign: "center" }}>
                        <span className="AppFont" style={{ fontSize: "3em" }}>{this.state.descriptionTitle}</span>
                        <br />
                        <span style={{color: "#198532", fontSize: "4em"}}>${this.state.descPrice}</span>
                        <p style={{fontSize: "22pt"}}>{this.state.descriptionText}</p>
                    </div>

                </Modal>
                <List 
                    grid={{
                        gutter: 16, xs: 1, sm: 1, md: 2, lg: 3, xl: 3, xxl: 3,
                    }} >{listItems}</List>
            </div>
        );
    }
}
export default GiftCards;