import React, { Component } from 'react';
import _ from "underscore";
import { observer } from 'mobx-react';
import Modal from 'react-modal';
import { Icon, Button, Input, message } from 'antd';
import { sha512 } from 'hash-anything';

const headerFontSize = "14pt";

Modal.setAppElement('#root')
@observer(["UserStore"])
class LoginButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            email: "",
            hashPass: "",
            family: "",
            loginModalIsOpen: false,
            buttons: <div>
                <Button style={_.extend(this.props.style || {}, { marginLeft: "4px", backgroundColor: "#a1e0ff", borderRadius: "40px", color: 'white', fontWeight: "bold" })} size="large" onClick={() => { this.setState({ loginModalIsOpen: true, registerModalIsOpen: false }) }}>Log In</Button>
            </div>
        };
        this.closeLoginModal = this.closeLoginModal.bind(this);
        this.submitLogin = this.submitLogin.bind(this);
    }

    closeLoginModal() {
        this.setState({ loginModalIsOpen: false });
    }

    componentDidMount() {
        document.addEventListener("keyup", event => {
            if (event.key !== "Enter") return;

            if (this.state.loginModalIsOpen) {
                this.submitLogin();
            }
        });
    }

    submitLogin() {
        const { onLoggedIn, UserStore } = this.props;
        let self = this;
        let email = this.state.email;
        let passHash = this.state.hashPass;
        if (this.state.email === "") {
            message.warning("Must have email");
        } else if (this.state.hashPass === "") {
            message.warning("Must have a password");
        } else {
            this.setState({
                loginModalIsOpen: false,
                buttons: <Icon type="loading" />
            });
            fetch(`${process.env.REACT_APP_BACKEND_URL}/auth`, {
                body: JSON.stringify({ email, passHash }),
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST'
            }).then((response) => {
                if (response.status === 401 || response.status === 403) {
                    return message.error("Email or password does not match database!");
                } else {
                    return response.json().then((res) => UserStore.handleLoggedIn(res));
                }
            })
                .catch(err => {
                    window.alert(err);
                    this.setState({ loading: false });
                });

            this.setState({
                buttons: <div>
                    <Button style={{ marginLeft: "4px", backgroundColor: "#a1e0ff", borderRadius: "40px", color: 'white', fontWeight: "bold" }} size="large" onClick={() => { self.setState({ loginModalIsOpen: true, registerModalIsOpen: false }) }}>Log In</Button>
                </div>
            });
        }
    }

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.state.loginModalIsOpen}
                    onRequestClose={this.closeLoginModal}
                    contentLabel="Login Modal"
                    className="register-modal"
                    overlayClassName="register-modal-overlay">
                    <div className="AppFont" style={{ fontSize: headerFontSize, color: "white", textAlign: "center" }}>
                        Email <Input style={{ textAlign: "center" }} type="email" onChange={(e) => { this.setState({ email: e.target.value }) }} />
                        <br />
                Password <Input id="password-login" style={{ textAlign: "center" }} type="password" onChange={(e) => { this.setState({ hashPass: sha512(e.target.value) }) }} />
                        <br />
                        <br />
                        <Button className="btn-submit" id="submitBtn-login" size="large" onClick={this.submitLogin}>Submit</Button>
                    </div>
                </Modal>
                {this.state.buttons}
            </div>
        );
    }
}

export default LoginButton;