import React, { Component } from 'react';

class FourOhFour extends Component {
    constructor(props) {
        super(props);
        this.state = {  };
    }
    render() {
        return (<div>
            Page not found
        </div>
        );
    }
}

export default FourOhFour;