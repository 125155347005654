import axios from "axios";
import { message } from "antd";
const url = process.env.REACT_APP_BACKEND_URL;

export async function updateGroup(groupId, accessToken, mongoUpdate) {
    return axios.put(url + "/groups/update/" + groupId, {mongoUpdate}, {
        headers: { Authorization: `Bearer ${accessToken}` }
    }).then(function (response) {
        return response;
    })
        .catch(function (error) {
            return error;
        });

}
