import React, { Component } from 'react';
import { ConfigProvider, Table, Icon, Button, notification, Popconfirm } from 'antd';
import AddPersonalNote from './AddPersonalNote';
import { deletePrivateNote } from '../api_calls/user';
import { observer } from 'mobx-react';

const customizeRenderEmpty = () => (
    <div style={{ textAlign: 'center', }}>
        <Icon type="smile" style={{ fontSize: 50 }} />
        <p>No Notes Found</p>
    </div>
);

@observer(['UserStore', 'AuthStore', 'FamilyStore'])
class MyNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.renderTables = this.renderTables.bind(this);
        this.tableData = this.tableData.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
    }
    confirmDelete(note) {
        const { UserStore, AuthStore } = this.props;
        notification.open({
            message: 'Deleting your note',
            icon: <Icon type="loading" style={{ color: '#108ee9' }} />,
            duration: 1
        });

        console.log(note);
        let user = UserStore.childViewActive ? UserStore.activeChild.id : UserStore.user._id;
        deletePrivateNote(user, note.body).then(() => {
            UserStore.refreshUser(AuthStore.userAuth.auth.accessToken);
        });
    }
    tableData() {
        const { UserStore, FamilyStore } = this.props;
        const getUserName = (userId) => {
            let person = this.props.FamilyStore.family.find(member => member._id === userId);
            return person ? person.name : (userId || "Nobody");
        }
        return {
            dataSource: UserStore.user.privateNotes.map((note, index) => {
                return {
                    key: index,
                    body: note.body,
                    about: getUserName(note.aboutPerson),
                    actions: <div><Popconfirm title="Are you sure delete this note?" okText="Yes" onConfirm={this.confirmDelete.bind(this, note)} cancelText="No">
                        <Button className="AppFont" style={{ color: "white", backgroundColor: "#ED553B" }}><Icon style={{ fontSize: "16pt" }} type="delete" /></Button>
                    </Popconfirm></div>
                }
            }),

            columns: [
                {
                    title: 'About',
                    dataIndex: 'about',
                    key: 'about',
                },
                {
                    title: 'Note',
                    dataIndex: 'body',
                    key: 'body',
                },
                {
                    title: 'Actions',
                    dataIndex: 'actions',
                    key: 'actions',
                }
            ]
        }

    }
    renderTables() {
        return <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Table dataSource={this.tableData().dataSource} columns={this.tableData().columns}>
            </Table>
        </ConfigProvider >
    }
    render() {
        return (
            <div>
                <span className="AppFont" style={{}}>These are private notes that only you can see.</span>
                <AddPersonalNote />
                {this.renderTables()}
            </div>
        );
    }
}

export default MyNotes;