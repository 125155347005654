import User from '../models/UserModel';
import UserStore from "./UserStore";
import AuthStore from "./AuthStore";
import { types } from "mobx-state-tree";
import { getFamilyMembers, getGroup } from '../api_calls/user';
import { getEvents } from '../api_calls/event';
import MyListStore from './MyListStore';
import _ from 'underscore';

const Birthday = types.model({
    date: types.Date,
    name: types.string
});

const GroupSettings = types.model({
    allowRankings: types.optional(types.boolean, false),
    rankingMax: types.optional(types.number, 1)
});

const GroupRole = types.model({roles:types.optional(types.array(types.string), [])});

const defaultGroupSetting = {
    allowRankings: false,
    rankingMax: 1
};

const defaultGroup = {
    _id: "-",
    name: "-",
    creator: "-",
    settings: defaultGroupSetting,
    roles: {}
};

const Group = types.model(
    {
        _id: types.optional(types.identifier, "-"),
        name: types.string,
        users: types.optional(types.array(types.string), []),
        creator: types.optional(types.string, ""),
        settings: types.optional(GroupSettings, defaultGroupSetting),
        roles: types.map(GroupRole)
    }
);

const GroupEvent = types.model({
    _id: types.optional(types.identifier, "-"),
    creatorId: types.optional(types.string, ""),
    groupId: types.optional(types.string, ""),
    userId: types.optional(types.string, ""),
    title: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    category: types.optional(types.string, ""),
    date: types.number,
    isOneTime: types.boolean,
    recurr: types.optional(types.string, "")
});

const FamilyStoreModel = types
    .model('FamilyStore', {
        loading: types.boolean,
        loaded: types.boolean,
        eventsLoaded: types.boolean,
        activeGroupId: types.optional(types.string, ""),
        activeGroupName: types.optional(types.string, ""),
        family: types.optional(types.array(User), []),
        birthdays: types.optional(types.array(types.Date), []),
        bdaysUnder30: types.optional(types.array(Birthday), []),
        nextBirthday: types.optional(types.Date, new Date()),
        nextBirthdayName: types.optional(types.string, ""),
        activeGroup: types.optional(Group, defaultGroup),
        events: types.optional(types.array(GroupEvent), [])
    })
    .views(self => {
        return {
            isLoaded: () => self.loaded,
            isLoading: () => self.loading
        }
    })
    .actions(self => {
        return {
            loadEvents: async (groupId = self.activeGroupId, token = AuthStore.userAuth.auth.accessToken) => {
                self.setEventsLoaded(false);
                getEvents({ groupId, token }).then((res) => {
                    if (res && res.data) {
                        self.setEvents(res.data);
                    }
                    self.setEventsLoaded(true);
                });
            },
            setEvents: (eventsToSet) => {
                self.events = eventsToSet;
            },
            setEventsLoaded: (status) => {
                self.eventsLoaded = status;
            },
            setFamily: async (family, me) => {
                self.setLoading(true);
                self.setLoaded(false);
                getFamilyMembers(family, AuthStore.userAuth.auth.accessToken).then((members) => {
                    let membersWithoutMe = [];
                    let birthdays = [];
                    if (members) {
                        members.forEach(member => {
                            if (member._id !== me) {
                                membersWithoutMe.push(member);
                                let d = new Date();
                                d.setDate(member.birthDay);
                                d.setMonth(member.birthMonth - 1);
                                let nextYearBirthday = new Date();
                                nextYearBirthday.setMonth(member.birthMonth - 1);
                                nextYearBirthday.setDate(member.birthDay);
                                nextYearBirthday.setFullYear(new Date().getFullYear() + 1);
                                birthdays.push({ date: d, name: member.name });
                                birthdays.push({ date: nextYearBirthday, name: member.name });
                            }
                        });

                        birthdays.sort((a, b) => {
                            return a.date.getTime() - b.date.getTime();
                        });
                        let currentNextUpBday;
                        let currentNextUpBdayName;
                        let stillLooking = true;
                        let stillLookingWithin30 = false;
                        let thirtyDaysTime = 1000 * 60 * 60 * 24 * 60;
                        let indexFlag = 0;
                        let bdaysDateArray = [];
                        let bdaysUnder30Array = [];
                        birthdays.forEach((birthday, index) => {
                            bdaysDateArray.push(birthday.date);

                            if (stillLooking) {
                                if (Date.now() < birthday.date.getTime()) {
                                    stillLooking = false;
                                    if (birthday.date.getTime() - Date.now() < thirtyDaysTime) {
                                        stillLookingWithin30 = true;
                                    }
                                    indexFlag = index;
                                    currentNextUpBday = birthday.date;
                                    currentNextUpBdayName = birthday.name;

                                }
                            }
                            if (stillLookingWithin30 && index > indexFlag) {
                                if (Date.now() + thirtyDaysTime >= birthday.date.getTime()) {
                                    bdaysUnder30Array.push(birthday);

                                }

                            }
                        });
                        self.setNextUpBday(currentNextUpBday);
                        self.setNextUpBdayName(currentNextUpBdayName);
                        self.setBdaysUnder30(bdaysUnder30Array);
                        self.setBirthdays(bdaysDateArray);
                        self.setFamilyMembers(membersWithoutMe);
                        self.setLoading(false);
                        self.setLoaded(true);
                    }
                });
            },
            setActiveGroup: (groupId) => {
                self.setLoading(true);
                self.setLoaded(false);
                self.activeGroupId = groupId;
                self.setActiveGroupName(groupId);
                UserStore.setInviteUrl(process.env.REACT_APP_FRONTEND_URL + "invite/" + groupId);
                self.setFamily(groupId, UserStore.childViewActive ? UserStore.activeChild.id : UserStore.user._id);
                if (groupId) {
                    self.loadEvents(groupId, AuthStore.userAuth.auth.accessToken);
                }
                else {
                    self.setEventsLoaded(true);
                }
                self.setGroup(groupId);
                MyListStore.determineRanking(UserStore.childViewActive ? _.findWhere(UserStore.children, { _id: UserStore.activeChild.id }) : UserStore.user, groupId);
            },
            setGroup: (groupId) => {
                getGroup(groupId, AuthStore.userAuth.auth.accessToken).then((res) => {
                    self._setGroup(res);
                })
            },
            _setGroup: (g) => {
                self.activeGroup = g;
            },
            setActiveGroupName: (groupId) => {
                let name = UserStore.user.groups.length !== 0 ? (UserStore.user.groups.find((group) => { return group.id === groupId }) ?
                    UserStore.user.groups.find((group) => { return group.id === groupId }).name : "") : "";
                self.activeGroupName = name;
            },
            setNextUpBday: (day) => {
                self.nextBirthday = day;
            },
            setNextUpBdayName: (name) => {
                self.nextBirthdayName = name;
            },
            setBirthdays: (birthdays) => {
                self.birthdays = birthdays;
            },
            setBdaysUnder30: (birthdays) => {
                self.bdaysUnder30 = birthdays;
            },
            setLoading: (loading) => {
                self.loading = loading;
            },
            setLoaded: (loaded) => {
                self.loaded = loaded;
            },
            setFamilyMembers: (members) => {
                self.family = members;
            }

        }
    });


const FamilyStore = FamilyStoreModel.create({
    loading: false,
    loaded: false,
    eventsLoaded: false,
    family: [],
    activeGroup: { id: "guest", name: "guest" }
});

export default FamilyStore;