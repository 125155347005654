import React, { Component } from 'react'
import { lazy, Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Switch, Route } from "react-router-dom";
import { createGlobalStyle } from 'styled-components';



import Footer from "./Footer";
import Header from "./Header";



const GlobalStyles = createGlobalStyle`

body,
html,
a {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-weight: bold;


body {
    margin:0;
    padding:0;
    border: 0;
    outline: 0;    
    overflow-x: hidden;
}

::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar
{
  width: 12px;
  background-color: rgba(255, 255, 255, 0.3);
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #5fb4de;
}

a:hover {
    color: #F6D55C;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    color: white;
    font-size: 2.575rem;
    line-height: 3.0625rem;
  
    @media only screen and (max-width: 414px) {
      font-size: 1.625rem;
    }
}

p {
    color: #343D48;
    font-size: 1.125rem;
    font-weight: bold;
    font-family: 'M PLUS Rounded 1c', sans-serif;
}

h1 {
    font-weight: 600;
}

a {
    text-decoration: none;
    outline: none;
    color: #343D48;
    font-size: 16px;

    :hover {
        color: #F6D55C;
    }
}

*:focus {
    outline: none;
}

.about-block-image svg {
    text-align: center;
}

.ant-drawer-body {
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    text-align: left;
    padding-top: 2.5rem;
    padding-right: 2rem;
}

.anticon,
.ant-notification-notice-icon-success {
    color: rgb(255,130,92);
}
`;

const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "LandingPage",
  },
  {
    path: ["/support", "/help"],
    exact: true,
    component: "SupportPage"
  },
  {
    path: ["/terms", "/terms-of-service"],
    exact: true,
    component: "TermsPage"
  },
];

class Router extends Component {



  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={null}>
          
          <GlobalStyles />
          <Header  props={this.props} />
          <Switch>
            {routes.map((routeItem) => {
              return (
                <Route
                  props={this.props}
                  key={routeItem.component}
                  path={routeItem.path}
                  exact={routeItem.exact}
                  component={lazy(() => import(`./${routeItem.component}`))}
                />
              );
            })}
          </Switch>
          <Footer />
        </Suspense>
      </BrowserRouter>
    );
  }
};

export default Router;
